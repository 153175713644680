import React, { useState } from "react";
import InputNumber from "../../../../../shared/components/inputs/InputNumber/InputNumber";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useLoading } from "../../../../../shared/context/LoadingContext";
import toast from "react-hot-toast";
const NUMBER_REGEX = /^\d+$/;

function ModalConfiguracoesAvaliacao({
  openModal,
  handleModalClose,
  modalData,
  handleUpdate,
  clinicaId,
  sendRequest,
  auth,
}) {
  const [mensagemDeErro, setMensagemDeErro] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { startLoading, stopLoading } = useLoading();

  const [dados, setDados] = useState({
    preco: modalData.preco,
    ativo: modalData.ativo,
    atualizadoPor: modalData.atualizadoPor,
    desativadoPor: modalData.desativadoPor,
  });

  const submitHandler = async () => {
    try {
      setIsSubmitting(true);
      startLoading();

      const formData = new FormData();
      formData.append("nomeConfiguracao", "avaliacao");
      formData.append("atualizadoPor", auth.userId);
      formData.append("dadosAtualizacao", JSON.stringify(dados));

      const novaConfiguracao = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/configuracoes/${clinicaId}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      toast.success("Configuração da avaliação atualizada com sucesso!");
      handleUpdate(novaConfiguracao.configuracao);
    } catch (err) {
    } finally {
      stopLoading();
      setIsSubmitting(false);
      handleModalClose();
    }
  };

  const changePrecoHandler = (preco) => {
    if (preco < 0) {
      setMensagemDeErro("O campo preço, tem valor negativo.");
    } else if (!NUMBER_REGEX.test(preco)) {
      setMensagemDeErro("O campo preço, tem valor inválido.");
    } else {
      setDados((current) => ({ ...current, preco: preco }));
      setMensagemDeErro("");
    }
  };

  return (
    <Modal
      open={openModal}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="fluxo-atendimento__modal__box adicionar-categoria__modal"
        id="modal__adicionar-categoria"
      >
        <h1 className="fluxo-atendimento__modal__titulo">
          Configurações de Avaliação
        </h1>
        <div className="adicionar-categoria__modal__container">
          <InputNumber
            className="adicionar-categoria__modal__container--span2"
            label="Preço*"
            min={0}
            initialValue={modalData.preco}
            handleChange={changePrecoHandler}
          />
        </div>

        {mensagemDeErro && (
          <div className="erroContainer__categoria-caixa">{mensagemDeErro}</div>
        )}

        <div className="fluxo-atendimento__modal__bottom_btns">
          <span
            className="cancel-btn"
            onClick={handleModalClose}
            style={{ display: "block" }}
          >
            Fechar
          </span>

          <span
            disabled={isSubmitting}
            className="blue-button"
            onClick={submitHandler}
          >
            {isSubmitting ? "Guardando" : "Guardar"}
          </span>
        </div>
      </Box>
    </Modal>
  );
}

export default ModalConfiguracoesAvaliacao;
