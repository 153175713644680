import React, { createContext, useState, useCallback, useContext } from "react";
import { AuthContext } from "./auth-context";
import { useHttpClient } from "../hooks/http-hook";

export const NotificationsContext = createContext({
  notifications: [],
  fetchNotifications: () => {},
  addNotification: () => {},
  removeNotification: () => {},
  clearAllNotifications: () => {},
});

export const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const fetchNotifications = useCallback(
    async (clinicaId) => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/notificacoes/clinica/${clinicaId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setNotifications(
          responseData.notificacoes.filter((n) => n.user.includes(auth.userId))
        );
      } catch (err) {
        console.error("Error fetching notifications:", err);
      }
    },
    [auth, sendRequest]
  );

  const addNotification = useCallback((notification) => {
    setNotifications((current) => [...current, notification]);
  }, []);

  const removeNotification = useCallback(
    async (notificationId) => {
      const formData = new FormData();
      formData.append("userId", auth.userId);
      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/notificacoes/${notificationId}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setNotifications((current) =>
          current.filter((notification) => notification.id !== notificationId)
        );
      } catch (err) {
        console.error("Error removing notification:", err);
      }
    },
    [auth.token, sendRequest]
  );

  const clearAllNotifications = useCallback(async () => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/notificacoes/todas/${auth.userId}`,
        "DELETE",
        {},
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      setNotifications([]);
    } catch (err) {
      console.error("Error clearing notifications:", err);
    }
  }, [auth.userId, auth.token, sendRequest]);

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        fetchNotifications,
        addNotification,
        removeNotification,
        clearAllNotifications,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
