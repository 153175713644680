import { useState, useEffect, useContext } from "react";
import moment from "moment";
import InputSelect from "../../../../../../../shared/components/inputs/InputSelect/InputSelect";

import { ClinicaContext } from "../../../../../../../shared/context/clinica-context";
//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useLoading } from "../../../../../../../shared/context/LoadingContext";
import toast from "react-hot-toast";

import "./ModalEntradaDebito.scss";

const formasPagamento = [
  "Dinheiro",
  "Cheque",
  // "Cheque Visado",
  // "Transferência de crédito",
  "Internet banking",
  // "Cartão do banco",
  // "Débito direto da conta bancária",
  "Cartão de crédito",
  // "Cartão de débito",
];

function ModalReceberEntradaDebito({
  openModalReceberEntrada,
  handleCloseModalReceberEntrada,
  sendRequest,
  updateEntradas,
  auth,
  clinicaId,
  emitirDocEletronicoReembolso,
  cliente,
  entrada,
}) {
  const { startLoading, stopLoading } = useLoading();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openModal, setOpenModal] = useState(openModalReceberEntrada);
  const [metodoPagamento, setMetodoPagamento] = useState(formasPagamento[0]);
  const [dataPagamento, setDataPagamento] = useState(moment());
  const [caixa, setCaixa] = useState(
    entrada.caixa._id ? entrada.caixa._id : entrada.caixa.id
  );
  const [caixas, setCaixas] = useState([]);

  useEffect(() => {
    const fetchCaixas = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/caixas/clinica/${clinicaId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        setCaixas(
          responseData.caixas.map((cai) => {
            return { id: cai.id, value: cai.caixa };
          })
        );
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchCaixas();
  }, [auth.token, clinicaId, sendRequest]);

  const clinica = useContext(ClinicaContext);

  useEffect(() => {
    setOpenModal(openModalReceberEntrada);
  }, [openModalReceberEntrada]);

  const metodoPagamentoChangeHandler = (valor) => {
    setMetodoPagamento(valor);
  };

  const receberEntradaHandler = async (emit) => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("dataPagamento", dataPagamento);
      formData.append("metodoPagamento", metodoPagamento);
      formData.append("recebidoPor", auth.userId);
      formData.append("caixa", caixa);

      //LEMBRA DE ADICIONAR COMPROVATIVO MAIS TARDE
      let entradaAtualizada;
      try {
        entradaAtualizada = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/receber/${entrada._id}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      if (emit) {
        emitirDocEletronicoReembolso(
          entradaAtualizada.entrada,
          null,
          "FRE",
          "atualizar"
        );
      } else {
        toast.success("Entrada recebida com sucesso!");
        updateEntradas(entradaAtualizada.entrada, "atualizar");
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setIsSubmitting(false);
      handleCloseModalReceberEntrada();
    }
  };

  const preReceberEntradaHandler = (e, emit) => {
    if (!dataPagamento) {
      toast.error("Introduza a data de recebimento");
    } else if (!moment.isMoment(dataPagamento) || !dataPagamento.isValid()) {
      toast.error("Introduza a data de recebimento corretamente");
    } else if (!isSubmitting) {
      setIsSubmitting(true);
      receberEntradaHandler(emit);
    }
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModalReceberEntrada}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="fluxo-atendimento__modal__box adicionar-entrada__modal"
          id="modal__adicionar-entrada"
        >
          <h1 className="fluxo-atendimento__modal__titulo">
            Receber entrada - {entrada.descricao}
          </h1>
          {/* {entrada && ( */}
          <div className="adicionar-entrada__modal__container">
            <div className="customInputContainer adicionar-despesa__modal__container--span2">
              <input
                type="text"
                defaultValue={entrada.descricao}
                className="customInput inputNumber"
                id="descricao"
                style={{ pointerEvents: "none", color: "#88959e" }}
                readOnly
              />
              <label className="customInputLabel" htmlFor="descricao">
                Descricao
              </label>
            </div>

            <div className="customInputContainer adicionar-despesa__modal__container--span1">
              <input
                type="text"
                defaultValue={entrada.valor}
                className="customInput inputNumber"
                id="valor"
                style={{ pointerEvents: "none", color: "#88959e" }}
                readOnly
              />
              <label className="customInputLabel" htmlFor="valor">
                Valor
              </label>
            </div>

            <LocalizationProvider
              dateAdapter={AdapterMoment}
              className="adicionar-despesa__modal__container--span1"
            >
              <DesktopDatePicker
                className="valorDataCaixa__fields--field muiDatePicker"
                label="Data do Pagamento*"
                inputFormat="DD-MM-YYYY"
                value={dataPagamento}
                onChange={(value) => setDataPagamento(value)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

            <div className="customInputContainer adicionar-despesa__modal__container--span2">
              <input
                type="text"
                defaultValue={entrada.categoria.categoria}
                className="customInput inputNumber"
                id="categoria"
                style={{ pointerEvents: "none", color: "#88959e" }}
                readOnly
              />
              <label className="customInputLabel" htmlFor="categoria">
                Categoria
              </label>
            </div>

            <div className="customInputContainer adicionar-despesa__modal__container--span2">
              <input
                type="text"
                defaultValue={cliente?.nome}
                className="customInput inputNumber"
                id="cliente"
                style={{ pointerEvents: "none", color: "#88959e" }}
                readOnly
              />
              <label className="customInputLabel" htmlFor="cliente">
                Cliente
              </label>
            </div>

            <textarea
              cols="30"
              rows="7"
              placeholder="observacao"
              value={entrada.observacao}
              readOnly
              className="adicionar-entrada__modal__container__textarea"
              style={{ color: "#88959e" }}
            ></textarea>
          </div>

          <div
            className="adicionar-despesa__modal__pagamento"
            style={{ marginTop: 25, marginBottom: 25 }}
          >
            <InputSelect
              className="adicionar-entrada__modal__container--span1"
              label="Forma de pagamento"
              initialValue={metodoPagamento}
              handleChange={metodoPagamentoChangeHandler}
              items={formasPagamento}
            />

            {caixas && caixa && (
              <div className="customInputContainer adicionar-entrada__modal__container--span1">
                <select
                  onChange={(e) => setCaixa(e.target.value)}
                  className="customInputSelect"
                  value={caixa}
                >
                  {caixas &&
                    caixa &&
                    caixas.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.value}
                      </option>
                    ))}
                </select>
                <label className="customInputLabelSelect">Caixa</label>
              </div>
            )}
          </div>
          <div className="fluxo-atendimento__modal__bottom_btns">
            <span
              className="cancel-btn"
              onClick={handleCloseModalReceberEntrada}
              style={{ display: "block" }}
            >
              Fechar
            </span>

            <span
              disabled={isSubmitting}
              className="blue-button"
              onClick={preReceberEntradaHandler}
            >
              {isSubmitting ? "Recebendo..." : "Receber"}
            </span>

            {/*Emitir FRE*/}
            {!entrada.documentoEletronico && (
              <span
                disabled={isSubmitting}
                className={isSubmitting ? "cancel-btn" : "blue-button"}
                onClick={preReceberEntradaHandler.bind(null, null, "emitirFRE")}
              >
                {isSubmitting
                  ? "Recebendo e emitindo FRE..."
                  : "Receber e emitir FRE"}
              </span>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalReceberEntradaDebito;
