import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

// import "./ModalAdicionarEvolucao.scss";
import toast from "react-hot-toast";
import { useLoading } from "../../../../../../../shared/context/LoadingContext";

import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";

function ModalVerEditarConsulta({
  handleCloseModal,
  open,
  dados,
  auth,
  sendRequest,
  handleModalSubmitSuccess,
  tipoModal,
}) {
  const { startLoading, stopLoading } = useLoading();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [relatorio, setRelatorio] = useState(dados.relatorio);
  const [dataInicioExecucao, setDataInicioExecucao] = useState(
    moment(dados.data_inicio, "DD-MM-YYYY HH:mm")
  );
  const [dataFimExecucao, setDataFimExecucao] = useState(
    moment(dados.data_fim, "DD-MM-YYYY HH:mm")
  );

  const submitHandler = async () => {
    startLoading();
    setIsSubmitting(true);
    try {
      const formData = new FormData();
      formData.append("relatorio", relatorio ? relatorio : "");
      formData.append("data_inicio", dataInicioExecucao);
      formData.append("data_fim", dataFimExecucao);
      formData.append("executada", true);

      formData.append("userId", auth.userId);
      let consultaRetornada;
      try {
        consultaRetornada = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/consultas/atualizarrelatorio/${dados._id}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      toast.success("Consulta executada com sucesso!");
      setIsSubmitting(false);
      handleModalSubmitSuccess(consultaRetornada.consulta);
      handleCloseModal();
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="fluxo-atendimento__modal__box" id="dividas__modal">
        <h1 className="fluxo-atendimento__modal__titulo">
          {tipoModal === "ver" ? "Ver " : ""}Relatório da consulta -{" "}
          {dados.cliente.nome}
        </h1>

        <div className="modal-executar-procedimentos__datetimeContainer">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              className="planos-tratamento__modal__datePicker planos-tratamento__modal__datePicker--1"
              label="Data Inicio"
              inputFormat="DD-MM-YYYY HH:mm"
              value={dataInicioExecucao}
              onChange={(value) => setDataInicioExecucao(value)}
              renderInput={(params) => <TextField {...params} />}
              readOnly={tipoModal === "ver"}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              className="planos-tratamento__modal__datePicker planos-tratamento__modal__datePicker--2"
              label="Data Fim"
              inputFormat="DD-MM-YYYY HH:mm"
              value={dataFimExecucao}
              onChange={(value) => setDataFimExecucao(value)}
              renderInput={(params) => <TextField {...params} />}
              readOnly={tipoModal === "ver"}
            />
          </LocalizationProvider>
        </div>

        <div className="adicionarEvolucaoContainer">
          <textarea
            className="executarProcedimento__container__textarea"
            placeholder="Escreva aqui o relatório da consulta."
            cols="30"
            rows="5"
            value={relatorio}
            onChange={(e) => setRelatorio(e.target.value)}
            readOnly={tipoModal === "ver"}
          />
        </div>

        <div
          style={{
            gridColumn: "1/-1",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "25px",
          }}
        >
          {tipoModal === "executar" && (
            <span
              disabled={isSubmitting}
              className="blue-button"
              onClick={submitHandler}
              style={{ marginRight: "10px" }}
            >
              {isSubmitting ? "Guardando..." : "Executar"}
            </span>
          )}
          <span className="cancel-btn" onClick={handleCloseModal}>
            Fechar
          </span>
        </div>
      </Box>
    </Modal>
  );
}

export default ModalVerEditarConsulta;
