import React, { useEffect, useState } from "react";
import { useHttpClient } from "../../../../shared/hooks/http-hook";
import moment from "moment";

import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useLoading } from "../../../../shared/context/LoadingContext";
import toast from "react-hot-toast";
import "./RelatoriosFInanceiro.scss";

function RelatoriosFinanceiro({ clinicaId, auth, dadosMapa }) {
  const { startLoading, stopLoading } = useLoading();
  const { sendRequest } = useHttpClient();
  const [dadosClinica, setDadosClinica] = useState();
  const [dataInicial, setDataInicial] = useState(
    moment().startOf("month").startOf("day")
  );
  const [dataFinal, setDataFinal] = useState(
    moment().endOf("month").endOf("day")
  );
  const [dataSelect, setDataSelect] = useState("Este mês");

  useEffect(() => {
    const fetchContasCorrentesEntradaDadosClinica = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/clinicas/${clinicaId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setDadosClinica(responseData.clinica);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchContasCorrentesEntradaDadosClinica();
  }, [auth.perm, auth.token, clinicaId, sendRequest]);

  const exportarMapaHandler = async () => {
    try {
      startLoading();
      const formData = new FormData();
      formData.append("dataInicial", dataInicial.format("DD/MM/YYYY"));
      formData.append("dataFinal", dataFinal.format("DD/MM/YYYY"));
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/mapavenda/${clinicaId}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      if (responseData.data.length === 0) {
        toast.error("Sem dados para gerar o mapa!");
      } else {
        //Enviar dados de entrada
        dadosMapa(
          responseData.data,
          dadosClinica,
          moment(dataInicial).format("DD-MM-YYYY"),
          moment(dataFinal).format("DD-MM-YYYY")
        );
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
    // //Parcelas
    // contasCorrentesAbsolut.forEach((c) => {
    //   c.contas.forEach((conta) => {
    //     conta.parcelas.forEach((p) => {
    //       if (
    //         (p.documentoEletronico &&
    //           p.documentoEletronico?.succeeded === true) ||
    //         p.cancelamentosDocumentosEletronicos?.length > 0
    //       ) {
    //         //O documento eletronico
    //         if (
    //           p.documentoEletronico &&
    //           p.documentoEletronico?.succeeded === true
    //         ) {
    //           if (
    //             moment(p.documentoEletronico?.created_at).isBetween(
    //               dataInicial,
    //               dataFinal,
    //               undefined,
    //               "[]"
    //             )
    //           ) {
    //             dadosFiltrados.push({
    //               nifEntidade: c.cliente.nif ? c.cliente.nif : "",
    //               entidade: c.cliente.nomeFiscal
    //                 ? c.cliente.nomeFiscal
    //                 : c.cliente.nome,
    //               serie: p.documentoEletronico.serie,
    //               tipoDocumento: p.documentoEletronico.tipoDocumento,
    //               nrDocumento: p.documentoEletronico.nrDocumento,
    //               data: moment(p.documentoEletronico.authorizedDateTime).format(
    //                 "DD/MM/YYYY"
    //               ),
    //               valor: p.quantiaPaga,
    //             });
    //           }
    //         }

    //         //Adicionar tb os cancelamentos se esses existirem e estiverem no date range
    //         if (
    //           p.cancelamentosDocumentosEletronicos &&
    //           p.cancelamentosDocumentosEletronicos?.length > 0
    //         ) {
    //           p.cancelamentosDocumentosEletronicos.forEach((cancelamento) => {
    //             if (
    //               cancelamento?.documentoCancelamento?.succeeded === true &&
    //               moment(
    //                 cancelamento?.documentoCancelamento?.created_at
    //               ).isBetween(dataInicial, dataFinal, undefined, "[]") &&
    //               cancelamento?.documentoCancelamento?.tipoDocumento !== "Event"
    //             ) {
    //               //Se o cancelamento pertencer ao alcance da data, são adicionados os documentos de cancelamento
    //               //e o documento cancelado à lista, mesmo que o documento cancelado não pertencer à data
    //               //Para evitar descrepancias no valor final do mapa de vendas
    //               //Documentos eliminados por eventos e eventos não vão no mapa de vendas
    //               dadosFiltrados.push({
    //                 nifEntidade: c.cliente.nif ? c.cliente.nif : "",
    //                 entidade: c.cliente.nomeFiscal
    //                   ? c.cliente.nomeFiscal
    //                   : c.cliente.nome,
    //                 serie: cancelamento.documentoCancelamento.serie,
    //                 tipoDocumento:
    //                   cancelamento.documentoCancelamento.tipoDocumento,
    //                 nrDocumento: cancelamento.documentoCancelamento.nrDocumento,
    //                 data: moment(
    //                   cancelamento.documentoCancelamento.authorizedDateTime
    //                 ).format("DD/MM/YYYY"),
    //                 valor: cancelamento.documentoCancelamento.valor
    //                   ? -cancelamento.documentoCancelamento.valor
    //                   : 0,
    //               });

    //               dadosFiltrados.push({
    //                 nifEntidade: c.cliente.nif ? c.cliente.nif : "",
    //                 entidade: c.cliente.nomeFiscal
    //                   ? c.cliente.nomeFiscal
    //                   : c.cliente.nome,
    //                 serie: cancelamento.documento.serie,
    //                 tipoDocumento: cancelamento.documento.tipoDocumento,
    //                 nrDocumento: cancelamento.documento.nrDocumento,
    //                 data: moment(
    //                   cancelamento.documento.authorizedDateTime
    //                 ).format("DD/MM/YYYY"),
    //                 valor: cancelamento.documentoCancelamento.valor
    //                   ? cancelamento.documentoCancelamento.valor
    //                   : 0,
    //               });
    //             }
    //           });
    //         }
    //       }
    //     });
    //   });
    // });

    // //Entradas
    // entradasAbsolut.forEach((e) => {
    //   if (
    //     (e.documentoEletronico && e.documentoEletronico?.succeeded === true) ||
    //     e.cancelamentosDocumentosEletronicos?.length > 0
    //   ) {
    //     if (e.tipo === "reembolso") {
    //       if (
    //         e.documentoEletronico &&
    //         e.documentoEletronico?.succeeded === true &&
    //         moment(e.documentoEletronico?.created_at).isBetween(
    //           dataInicial,
    //           dataFinal,
    //           undefined,
    //           "[]"
    //         )
    //       ) {
    //         dadosFiltrados.push({
    //           nifEntidade: e.cliente.nif ? e.cliente.nif : "",
    //           entidade: e.cliente.nomeFiscal
    //             ? e.cliente.nomeFiscal
    //             : e.cliente.nome,
    //           serie: e.documentoEletronico.serie,
    //           tipoDocumento: e.documentoEletronico.tipoDocumento,
    //           nrDocumento: e.documentoEletronico.nrDocumento,
    //           data: moment(e.documentoEletronico.authorizedDateTime).format(
    //             "DD/MM/YYYY"
    //           ),
    //           valor: e.valor,
    //         });
    //       }
    //       //Adicionar tb os cancelamentos se esses existirem e estiverem no date range
    //       if (
    //         e.cancelamentosDocumentosEletronicos &&
    //         e.cancelamentosDocumentosEletronicos?.length > 0
    //       ) {
    //         e.cancelamentosDocumentosEletronicos.forEach((cancelamento) => {
    //           if (
    //             cancelamento?.documentoCancelamento?.succeeded === true &&
    //             moment(
    //               cancelamento?.documentoCancelamento.created_at
    //             ).isBetween(dataInicial, dataFinal, undefined, "[]") &&
    //             cancelamento?.documentoCancelamento?.tipoDocumento !== "Event"
    //           ) {
    //             //Se o cancelamento pertencer ao alcance da data, são adicionados os documentos de cancelamento
    //             //e o documento cancelado à lista, mesmo que o documento cancelado não pertencer à data
    //             //Para evitar descrepancias no valor final do mapa de vendas
    //             //Documentos eliminados por eventos e eventos não vão no mapa de vendas
    //             dadosFiltrados.push({
    //               nifEntidade: e.cliente.nif ? e.cliente.nif : "",
    //               entidade: e.cliente.nomeFiscal
    //                 ? e.cliente.nomeFiscal
    //                 : e.cliente.nome,
    //               serie: cancelamento.documentoCancelamento.serie,
    //               tipoDocumento:
    //                 cancelamento.documentoCancelamento.tipoDocumento,
    //               nrDocumento: cancelamento.documentoCancelamento.nrDocumento,
    //               data: moment(
    //                 cancelamento.documentoCancelamento.authorizedDateTime
    //               ).format("DD/MM/YYYY"),
    //               valor: cancelamento.documentoCancelamento.valor
    //                 ? -cancelamento.documentoCancelamento.valor
    //                 : 0,
    //             });

    //             dadosFiltrados.push({
    //               nifEntidade: e.cliente.nif ? e.cliente.nif : "",
    //               entidade: e.cliente.nomeFiscal
    //                 ? e.cliente.nomeFiscal
    //                 : e.cliente.nome,
    //               serie: cancelamento.documento.serie,
    //               tipoDocumento: cancelamento.documento.tipoDocumento,
    //               nrDocumento: cancelamento.documento.nrDocumento,
    //               data: moment(
    //                 cancelamento.documento.authorizedDateTime
    //               ).format("DD/MM/YYYY"),
    //               valor: cancelamento.documentoCancelamento.valor
    //                 ? cancelamento.documentoCancelamento.valor
    //                 : 0,
    //             });
    //           }
    //         });
    //       }
    //     } else {
    //       //Para entradas de clientes fornecedores temos 2 tipos de cancelamentos
    //       //Cancelamentos de recibos e cancelamentos de FTEs.
    //       //Documentos eliminados por eventos e eventos não vão no mapa de vendas
    //       if (
    //         e.documentoEletronico &&
    //         e.documentoEletronico.succeeded === true &&
    //         moment(e.documentoEletronico?.created_at).isBetween(
    //           dataInicial,
    //           dataFinal,
    //           undefined,
    //           "[]"
    //         )
    //       ) {
    //         dadosFiltrados.push({
    //           nifEntidade: e.clienteFornecedor.nif
    //             ? e.clienteFornecedor.nif
    //             : "",
    //           entidade: e.clienteFornecedor.nome
    //             ? e.clienteFornecedor.nome
    //             : "",
    //           serie: e.documentoEletronico.serie,
    //           tipoDocumento: e.documentoEletronico.tipoDocumento,
    //           nrDocumento: e.documentoEletronico.nrDocumento,
    //           data: moment(e.documentoEletronico.authorizedDateTime).format(
    //             "DD/MM/YYYY"
    //           ),
    //           valor: e.valor,
    //         });
    //       }
    //       if (
    //         e.cancelamentosDocumentosEletronicos &&
    //         e.cancelamentosDocumentosEletronicos?.length > 0
    //       ) {
    //         e.cancelamentosDocumentosEletronicos.forEach((cancelamento) => {
    //           if (
    //             cancelamento?.documentoCancelamento?.succeeded === true &&
    //             moment(
    //               cancelamento?.documentoCancelamento?.created_at
    //             ).isBetween(dataInicial, dataFinal, undefined, "[]") &&
    //             cancelamento?.documentoCancelamento?.tipoDocumento !== "Event"
    //           ) {
    //             //Cancelamentos de FTES
    //             dadosFiltrados.push({
    //               nifEntidade: e.clienteFornecedor.nif
    //                 ? e.clienteFornecedor.nif
    //                 : "",
    //               entidade: e.clienteFornecedor.nome
    //                 ? e.clienteFornecedor.nome
    //                 : "",
    //               serie: cancelamento.documentoCancelamento.serie,
    //               tipoDocumento:
    //                 cancelamento.documentoCancelamento.tipoDocumento,
    //               nrDocumento: cancelamento.documentoCancelamento.nrDocumento,
    //               data: moment(
    //                 cancelamento.documentoCancelamento.authorizedDateTime
    //               ).format("DD/MM/YYYY"),
    //               valor: cancelamento.documentoCancelamento.valor
    //                 ? -cancelamento.documentoCancelamento.valor
    //                 : 0,
    //             });

    //             dadosFiltrados.push({
    //               nifEntidade: e.clienteFornecedor.nif
    //                 ? e.clienteFornecedor.nif
    //                 : "",
    //               entidade: e.clienteFornecedor.nome
    //                 ? e.clienteFornecedor.nome
    //                 : "",
    //               serie: cancelamento.documento.serie,
    //               tipoDocumento: cancelamento.documento.tipoDocumento,
    //               nrDocumento: cancelamento.documento.nrDocumento,
    //               data: moment(
    //                 cancelamento.documento.authorizedDateTime
    //               ).format("DD/MM/YYYY"),
    //               valor: cancelamento.documentoCancelamento.valor
    //                 ? cancelamento.documentoCancelamento.valor
    //                 : 0,
    //             });
    //           }
    //         });
    //       }
    //     }
    //   }
    // });
  };

  let startDate, endDate;

  const handleChangeDataSelect = (e) => {
    const value = e.target.value;

    if (e.target.value === "Hoje") {
      startDate = moment().startOf("day");
      endDate = moment().endOf("day");
    } else if (e.target.value === "Esta semana") {
      startDate = moment().startOf("week").startOf("day");
      endDate = moment().endOf("week").endOf("day");
    } else if (e.target.value === "Este mês") {
      startDate = moment().startOf("month").startOf("day");
      endDate = moment().endOf("month").endOf("day");
    } else if (e.target.value === "Mês passado") {
      startDate = moment().subtract(1, "month").startOf("month").startOf("day");
      endDate = moment().subtract(1, "month").endOf("month").endOf("day");
    } else {
      startDate = dataInicial;
      endDate = dataFinal;
    }

    setDataInicial(startDate);
    setDataFinal(endDate);
    setDataSelect(value);
  };

  return (
    <div>
      <div className="relatorio__filtros__container">
        <FormControl className="relatorio__filtros__container__selectContainer">
          <InputLabel id="demo-simple-select-label">Periodo</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={dataSelect}
            label="Periodo"
            onChange={handleChangeDataSelect}
          >
            <MenuItem value="Hoje">Hoje</MenuItem>
            <MenuItem value="Esta semana">Esta Semana</MenuItem>
            <MenuItem value="Este mês">Este mês</MenuItem>
            <MenuItem value="Mês passado">Mês passado</MenuItem>
            <MenuItem value="Escolher periodo">Escolher periodo</MenuItem>
          </Select>
        </FormControl>

        {dataSelect === "Escolher periodo" && (
          <div className="relatorio__datasContainer">
            <div className="relatorio__datasContainer__datePickerContainer">
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                className="adicionar-despesa__modal__container--span1"
              >
                <DesktopDatePicker
                  className="valorDataCaixa__fields--field muiDatePicker"
                  label="Data Inicial*"
                  inputFormat="DD-MM-YYYY"
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(value) => setDataInicial(value)}
                  value={dataInicial}
                />
              </LocalizationProvider>
            </div>
            <div className="relatorio__datasContainer__datePickerContainer">
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                className="adicionar-despesa__modal__container--span1"
              >
                <DesktopDatePicker
                  className="valorDataCaixa__fields--field muiDatePicker"
                  label="Data Final*"
                  inputFormat="DD-MM-YYYY"
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(value) => setDataFinal(value)}
                  value={dataFinal}
                />
              </LocalizationProvider>
            </div>
          </div>
        )}
      </div>
      <div>
        <button
          onClick={exportarMapaHandler}
          className="relatorio__filtros__gerarDocButton"
        >
          Gerar Mapa de Venda
        </button>
      </div>
    </div>
  );
}

export default RelatoriosFinanceiro;
