//MUI
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import { fixSpaces } from "../../../../../shared/util/helperFunctions";

import "./ModalMotivoCancelamentoEntradas.scss";
const motivosCancelamento = [
  { cod: 2, description: "Retificação - Art.º 65, n.º 2 CIVA" },
  { cod: 3, description: "Retificação - Art.º 65, n.º 3 CIVA" },
  { cod: 6, description: "Retificação - Art.º 65, n.º 6 CIVA" },
  { cod: 7, description: "Retificação - Art.º 65, n.º 7 CIVA" },
  { cod: 8, description: "Retificação - Art.º 65, n.º 8 CIVA" },
  { cod: 9, description: "Retificação - Art.º 65, n.º 9 CIVA" },
  // { cod: 0, description: "Outro" },
];

function ModalMotivoCancelamentoEntradas({
  open,
  handleCloseModal,
  modalData,
  handleCancelarRecebimentoEntrada,
  emitirDocumentoFiscalParaEntradas,
}) {
  const [motivoCancelamento, setMotivoCancelamento] = useState(
    motivosCancelamento[0]
  );
  const [erro, setErro] = useState("");
  const [outro, setOutro] = useState("");
  const [motivoEvent, setMotivoEvent] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const motivosCancelamentoChangeHandler = (e) => {
    const selectedMotivo = motivosCancelamento.find(
      (m) => m.cod === parseInt(e.target.value)
    );
    setMotivoCancelamento(selectedMotivo);
  };

  const beforeSubmitHandler = () => {
    if (
      (motivoCancelamento.cod === 0 &&
        fixSpaces(outro).length < 10 &&
        modalData.tipoDocumento !== "Event") ||
      (fixSpaces(motivoEvent).length < 10 &&
        modalData.tipoDocumento === "Event")
    ) {
      setErro(
        `Deve indicar pelo menos 10 carateres no campo (Descrição de Motivo de Emissão de ${modalData.tipoDocumento})`
      );
    } else {
      setIsSubmitting(true);
      submitHandler();
    }
  };

  const submitHandler = () => {
    setIsSubmitting(true);

    if (modalData.apenasEmitir) {
      emitirDocumentoFiscalParaEntradas(
        modalData.entrada._id,
        "NCE",
        "atualizar",
        motivoCancelamento
      );
    } else if (modalData.tipoDocumento !== "Event") {
      if (motivoCancelamento.cod === 0) {
        handleCancelarRecebimentoEntrada(
          modalData.entrada,
          true,
          {
            cod: motivoCancelamento.cod,
            description: outro.trim(),
          },
          modalData.tipoDocumento
        );
      } else {
        handleCancelarRecebimentoEntrada(
          modalData.entrada,
          true,
          motivoCancelamento,
          modalData.tipoDocumento
        );
      }
    } else {
      handleCancelarRecebimentoEntrada(
        modalData.entrada,
        true,
        motivoEvent,
        modalData.tipoDocumento
      );
    }

    /////////////////////////////////
    setIsSubmitting(false);
    handleCloseModal();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="fluxo-atendimento__modal__box motivoCancelamento__modal"
          id="modal__motivoCancelamento"
        >
          <h1 className="fluxo-atendimento__modal__titulo">
            Anular {modalData.tipoDocumentoAnular} - Motivo emissão de{" "}
            {modalData.tipoDocumento}
          </h1>

          <div className="motivoCancelamento__modal__container">
            {modalData.tipoDocumento === "Event" ? (
              <textarea
                className="motivoCancelamento__modal__textarea"
                cols={4}
                rows={4}
                onChange={(e) => setMotivoEvent(e.target.value)}
                value={motivoEvent}
                placeholder="Descrição do motivo de emissão do Evento"
              />
            ) : (
              <>
                <select
                  onChange={motivosCancelamentoChangeHandler}
                  className="motivoCancelamento__modal__select"
                >
                  {motivosCancelamento.map((m) => (
                    <option key={m.cod} value={m.cod}>
                      {m.description}
                    </option>
                  ))}
                </select>

                {motivoCancelamento.cod === 0 && (
                  <>
                    <textarea
                      className="motivoCancelamento__modal__textarea"
                      cols={4}
                      rows={4}
                      onChange={(e) => setOutro(e.target.value)}
                      value={outro}
                      placeholder={`Descrição do motivo de emissão de ${modalData.tipoDocumento}`}
                    />
                    <span className="motivoCancelamento__modal__motivoStringLenght">
                      {outro.length}{" "}
                      {outro.length === 1 ? "caracter" : "caracteres"}
                    </span>
                  </>
                )}
              </>
            )}
          </div>

          <div className="motivoCancelamento__modal__erro">{erro && erro}</div>

          <div
            style={{
              gridColumn: "1/-1",
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <span
              className="cancel-btn"
              onClick={handleCloseModal}
              style={{ display: "block" }}
            >
              Fechar
            </span>

            <span
              className="blue-button"
              disabled={isSubmitting}
              onClick={beforeSubmitHandler}
            >
              {isSubmitting ? "Enviando..." : "Enviar"}
            </span>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalMotivoCancelamentoEntradas;
