import React, { useState, useRef, useEffect } from "react";

const ClientAutocomplete = ({
  selectedClient,
  clients,
  onChange,
  disabled = false,
  className = "",
  label = "Cliente",
}) => {
  const [inputValue, setInputValue] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [filteredClients, setFilteredClients] = useState([]);
  const [isFocused, setIsFocused] = useState(false);

  const wrapperRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (selectedClient) {
      setInputValue(selectedClient.label);
    } else {
      setInputValue("");
    }
  }, [selectedClient]);

  const filterClients = (searchText) => {
    if (!searchText) return clients;

    const searchTerms = removeAccents(searchText.toLowerCase()).split(/\s+/);

    return clients
      .sort((a, b) => {
        const aStartsWith = removeAccents(a.label.toLowerCase()).startsWith(
          searchTerms[0]
        );
        const bStartsWith = removeAccents(b.label.toLowerCase()).startsWith(
          searchTerms[0]
        );
        if (aStartsWith && !bStartsWith) return -1;
        if (!bStartsWith && aStartsWith) return 1;
        return a.label.localeCompare(b.label);
      })
      .filter((client) => {
        const normalizedName = removeAccents(client.label.toLowerCase());
        const nameWords = normalizedName.split(/\s+/);

        return searchTerms.every((term) =>
          nameWords.some((word) => word.includes(term))
        );
      });
  };

  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setShowSuggestions(true);
    setFilteredClients(filterClients(value));
    setFocusedIndex(-1);

    if (!value) {
      onChange(null);
    }
  };

  const handleSelectClient = (client) => {
    setInputValue(client.label);
    setShowSuggestions(false);
    onChange(client);
    inputRef.current?.blur();
  };

  const handleKeyDown = (e) => {
    if (!showSuggestions) return;

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setFocusedIndex((prev) =>
          prev < filteredClients.length - 1 ? prev + 1 : prev
        );
        break;
      case "ArrowUp":
        e.preventDefault();
        setFocusedIndex((prev) => (prev > 0 ? prev - 1 : -1));
        break;
      case "Enter":
        e.preventDefault();
        if (focusedIndex >= 0 && filteredClients[focusedIndex]) {
          handleSelectClient(filteredClients[focusedIndex]);
        }
        break;
      case "Escape":
        setShowSuggestions(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSuggestions(false);
        if (selectedClient) {
          setInputValue(selectedClient.label);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [selectedClient]);

  // const highlightMatch = (text, query) => {
  //   if (!query) return text;
  //   const normalizedText = text.toLowerCase();
  //   const normalizedQuery = query.toLowerCase();
  //   const index = normalizedText.indexOf(normalizedQuery);

  //   if (index === -1) return text;

  //   return (
  //     <>
  //       {text.slice(0, index)}
  //       <span className="mui-style-highlight">
  //         {text.slice(index, index + query.length)}
  //       </span>
  //       {text.slice(index + query.length)}
  //     </>
  //   );
  // };

  return (
    <div ref={wrapperRef} className={`mui-style-autocomplete ${className}`}>
      <div
        className={`mui-style-input-container ${isFocused ? "focused" : ""} ${
          inputValue ? "has-value" : ""
        }`}
      >
        <input
          ref={inputRef}
          type="text"
          className="mui-style-input"
          value={inputValue}
          onChange={handleInputChange}
          onFocus={() => {
            setIsFocused(true);
            setShowSuggestions(true);
            setFilteredClients(filterClients(inputValue));
          }}
          onBlur={() => setIsFocused(false)}
          onKeyDown={handleKeyDown}
          disabled={disabled}
        />
        <label className="mui-style-label">{label}</label>
      </div>

      {showSuggestions && filteredClients.length > 0 && (
        <div className="mui-style-suggestions">
          {filteredClients.map((client, index) => {
            const sameNameClients = filteredClients.filter(
              (c) => c.label === client.label
            );
            const isDuplicate = sameNameClients.length > 1;

            return (
              <div
                key={client.id}
                className={`mui-style-suggestion ${
                  focusedIndex === index ? "focused" : ""
                }`}
                onClick={() => handleSelectClient(client)}
              >
                {/* <div>
                  {highlightMatch(client.label, inputValue)}
                  {isDuplicate && (
                    <span className="mui-style-client-id">
                      ID: #{client.id.slice(-4)}
                    </span>
                  )}
                </div> */}

                <div>
                  {client.label}
                  {isDuplicate && (
                    <span className="mui-style-client-id">
                      ID: #{client.id.slice(-4)}
                    </span>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ClientAutocomplete;
