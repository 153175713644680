import React from "react";
import { useContext, useEffect, useState, useCallback } from "react";
import "./HomeDentista.scss";
import Procedimentos from "../medicoAdministrador/components/Procedimentos";
import Sidebar from "../../../shared/components/sidebar/Sidebar";
import Navbar from "../../../shared/components/navbar/Navbar";
import Chart from "../../../shared/components/chart/Chart";
import { AuthContext } from "../../../shared/context/auth-context";
import { ClinicaContext } from "../../../shared/context/clinica-context";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import toast from "react-hot-toast";
import PdfListaAtendimentos from "../../../shared/components/PdfGenerator/PdfListaAtendimentos/PdfListaAtendimentos";
import DashboardIcon from "@mui/icons-material/Dashboard";

import ModalInfo from "../../../shared/components/UIElements/ModalInfo";

import AvaliacoesPorEfetuarModal from "../medicoAdministrador/components/AvaliacoesPorEfetuarModal";
import UrgenciasModal from "../medicoAdministrador/components/UrgenciasModal";

import Tasks from "../components/tasks/Tasks";
import InformacoesDaClinica from "../components/informacoesDaClinica/InformacoesDaClinica";

import useWebSocket from "../../../shared/hooks/use-websocket";
import "../../../shared/css/Checkbox.scss";

const DAILY_MESSAGES = [
  "Que seu dia seja tão produtivo quanto você fingiu ser na entrevista de emprego.",
  "Que seu dia seja mais tranquilo que sala de reunião vazia!",
  "Mantenha a postura de quem já recebeu o salário!",
  "Hoje você vai arrasar mais que funcionário em véspera de férias!",
  "Que seu dia seja mais próspero que conta bancária de patrão!",
  "Que hoje você tenha mais sorte que estagiário procurando café grátis!",
  "Seja mais positivo que avaliação de desempenho manipulada!",
  "Que seu dia seja mais agradável que ar condicionado no verão!",
  "Hoje você está mais importante que crachá de diretor!",
  "Que estejas mais animado que funcionário em dia de pagamento!",
  "Encare o dia com a confiança de quem tem backup de todos os arquivos!",
  "Hoje você está mais preparado que powerpoint de apresentação!",
  "Ques estejas mais determinado que estagiário querendo efetivação!",
  "Ques estejas mais relaxado que funcionário em home office!",
  "Que hoje você tenha mais sucesso que vírus em computador sem antivírus!",
  "Que seu dia seja mais feliz que o estagiário no dia do pagamento!",
  "Lembre-se que dormir no trabalho também é uma forma de meditação.",
  "Que seu dia seja mais organizado que sua mesa de trabalho!",
  "Que tenhas a energia de quem descobriu que amanhã é feriado!",
  "Hoje você está mais indispensável que café da manhã!",
  "Hoje você está mais essencial que post-it em monitor!",
  "Que seu dia seja mais próspero que hora extra em dezembro!",
  "Encare o trabalho como se fosse sexta-feira (mesmo que seja segunda)!",
  "Que seu dia seja mais produtivo que reunião sem o chefe!",
  "Ques tenhas o carisma de quem trouxe bolo pra equipe!",
  "Com a alegria de quem recebeu email de 'podem ir pra casa mais cedo'!",
  "Que seu dia seja mais criativo que desculpa pra sair mais cedo!",
  "Que tenhas a mesma energia do estagiário que faz o trabalho do gerente!",
  "Que tenhas a mesma vontade de trabalhar que político em fim de mandato!",
  "Mais um dia para fingir que gosta de acordar cedo!",
  "Que estejas mais motivado que funcionário em empresa falindo!",
];

function HomeDentista() {
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);
  const [userData, setUserData] = useState();
  const [urgencias, setUrgencias] = useState();
  const [openModalUrgencia, setOpenModalUrgencia] = useState(false);
  const [openModalAvaliacoesPorEfetuar, setOpenModalAvaliacoesPorEfetuar] =
    useState(false);

  const [avaliacoesPorEfetuar, setAvaliacoesPorEfetuar] = useState([]);
  const [estatisticaDeProcedimentos, setEstatisticaProcedimentos] = useState(
    []
  );
  const [listaAtendimentoData, setListaAtendimentoData] = useState({
    show: false,
  });
  const [listaPresenca, setListaPresenca] = useState([]);
  const [modalProps, setModalProps] = useState({
    show: false,
    icon: "",
    title: "",
    message: "",
    form: false,
  });

  const handleMessage = useCallback(
    (parsedSocketData) => {
      if (
        parsedSocketData.tabela === "urgencias" &&
        parsedSocketData.tipoDeOperacao === "insert"
      ) {
        const fetchcliente = async () => {
          try {
            const responseData = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/clientes/${parsedSocketData.documento.cliente}`,
              "GET",
              null,
              {
                Authorization: "Bearer " + auth.token,
              }
            );

            setUrgencias((current) => [
              ...current,
              {
                atendido: parsedSocketData.documento.atendido,
                cliente: responseData.cliente,
                created_at: parsedSocketData.documento.created_at,
                id: parsedSocketData.id,
              },
            ]);
          } catch (err) {}
        };

        fetchcliente();
      }

      if (
        parsedSocketData.tabela === "urgencias" &&
        parsedSocketData.tipoDeOperacao === "delete"
      ) {
        setUrgencias((current) =>
          current.filter((u) => u.id !== parsedSocketData.id)
        );
      }

      if (
        parsedSocketData.tabela === "notificacaos" &&
        parsedSocketData.documento.user === auth.userId
      ) {
        const fetchcliente = async () => {
          try {
            const responseData = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/clientes/${parsedSocketData.documento.cliente}`,
              "GET",
              null,
              {
                Authorization: "Bearer " + auth.token,
              }
            );

            if (parsedSocketData.documento.tipo === "avaliação") {
              setAvaliacoesPorEfetuar((current) => [
                ...current,
                {
                  idCliente: responseData.cliente.id,
                  cliente: responseData.cliente.nome,
                  created_at: parsedSocketData.documento.created_at,
                  id: parsedSocketData.id,
                },
              ]);
            }
          } catch (err) {}
        };

        fetchcliente();
      }

      if (parsedSocketData.tabela === "listapresencas") {
        const fetchListaPresenca = async () => {
          try {
            const responseData = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/listapresenca/${clinica.clinica._id}`,
              "GET",
              null,
              {
                Authorization: "Bearer " + auth.token,
              }
            );

            setListaPresenca(responseData.listaPresenca);
          } catch (err) {}
        };

        fetchListaPresenca();
      }
    },
    [auth, clinica.clinica._id, sendRequest]
  );

  useWebSocket(process.env.REACT_APP_WEBSOCKET_ADDRESS, handleMessage, [
    auth,
    clinica.clinica._id,
    sendRequest,
  ]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/users/${auth.userId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setUserData({
          nome: responseData.user.name,
          genero: responseData.user.genero,
        });
      } catch (err) {}
    };
    fetchUser();

    const fetchUrgencias = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/urgencias/clinica/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        setUrgencias(responseData.urgencias);
      } catch (err) {}
    };
    fetchUrgencias();

    const fetchAvaliacoesPorEfetuar = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/notificacoes/clinica/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        const avalList = [];
        responseData.notificacoes.forEach((n) => {
          if (n.tipo === "avaliação" && auth.userId === n.user) {
            avalList.push({
              id: n.id,
              idCliente: n.cliente.id,
              cliente: n.cliente.nome,
              created_at: n.created_at,
            });
          }
        });
        setAvaliacoesPorEfetuar(avalList);
      } catch (err) {}
    };
    fetchAvaliacoesPorEfetuar();

    const fetchEstatisticaProcedimentos = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/dashboardadministrador/estatisticaprocedimentos/${auth.userId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setEstatisticaProcedimentos(responseData.listaProcedimentos);
      } catch (err) {
        console.error("err", err);
      }
    };

    fetchEstatisticaProcedimentos();

    const fetchListaPresenca = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/listapresenca/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setListaPresenca(responseData.listaPresenca);
      } catch (err) {}
    };
    fetchListaPresenca();
  }, [sendRequest, auth.userId, clinica, auth.token]);

  //MODAL Urgencia
  const handleOpen = (tipo) => {
    if (tipo === "urgencia") {
      if (urgencias.length > 0) setOpenModalUrgencia(true);
    }
    if (tipo === "avaliacao") {
      if (avaliacoesPorEfetuar.length > 0)
        setOpenModalAvaliacoesPorEfetuar(true);
    }
  };

  const handleClose = () => {
    setOpenModalUrgencia(false);
    setOpenModalAvaliacoesPorEfetuar(false);
  };

  //Codigo para marcar cliente como atendido
  const handleAtendido = async (id) => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/urgencias/atendido/${id}`,
        "PATCH",
        {},
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      setUrgencias(
        urgencias.filter((el) => {
          return el.id !== id;
        })
      );

      toast.success("Cliente de urgência atendido com sucesso!");
      handleModalCancel();
    } catch (err) {}
  };

  const handleModalCancel = () => {
    setModalProps((currentModalProps) => {
      return { ...currentModalProps, show: false };
    });
  };

  const atendidoConfirmation = (id, nome) => {
    setOpenModalUrgencia(false);
    setModalProps({
      show: true,
      icon: "info",
      title: `Atendimento de urgência!`,
      message: `Tem a certeza que pretende marcar o cliente ${nome} com atendido?`,
      form: true,
      onCancel: handleModalCancel,
      onConfirm: handleAtendido.bind(null, id),
    });
  };

  const avaliacaoDispensada = (id) => {
    const tempAvaliacoes = avaliacoesPorEfetuar.filter((av) => av.id !== id);
    setAvaliacoesPorEfetuar(tempAvaliacoes);
  };

  ///////////////////////////////////////////////////

  const showSinglePage = () => {
    if (listaAtendimentoData.show)
      setListaAtendimentoData((current) => {
        return { ...current, show: false };
      });
  };

  const visualizarPdf = (lista) => {
    setListaAtendimentoData({ show: true, data: lista });
  };

  return (
    <>
      {listaAtendimentoData.show && (
        <PdfListaAtendimentos
          showPreviousPage={showSinglePage}
          listaAtendimentoData={listaAtendimentoData.data}
          medico={auth.nome}
          clinica={clinica.clinica.nome}
        />
      )}
      {!listaAtendimentoData.show && (
        <>
          <div className="home">
            <Sidebar />
            <div className="homeContainer">
              <Navbar
                title="dashboard"
                icon={DashboardIcon}
                avaliacaoDispensada={avaliacaoDispensada}
              />
              <div className="dashboard__ola">
                <span className="dashboard__ola__big">{`Olá, ${auth.nome}!`}</span>
                <span className="dashboard__ola__small">
                  {DAILY_MESSAGES[new Date().getDate() - 1]}
                </span>
              </div>
              <ModalInfo {...modalProps} />

              {openModalUrgencia && (
                <UrgenciasModal
                  handleClose={handleClose}
                  atendidoConfirmation={atendidoConfirmation}
                  urgencias={urgencias}
                  openModalUrgencia={openModalUrgencia}
                />
              )}
              {openModalAvaliacoesPorEfetuar && (
                <AvaliacoesPorEfetuarModal
                  handleClose={handleClose}
                  avaliacoesPorEfetuar={avaliacoesPorEfetuar}
                  openModalAvaliacoesPorEfetuar={openModalAvaliacoesPorEfetuar}
                />
              )}
              <div className="dashboard__top">
                {userData && estatisticaDeProcedimentos && (
                  <Chart
                    nome={userData.nome}
                    genero={userData.genero}
                    role={auth.role}
                    data={estatisticaDeProcedimentos}
                  />
                )}

                <div className="caixa dashboard__right">
                  <div className="dashboard__right__top">
                    <div className="dashboard__right__top-left">
                      <span className="box-title">Avaliações</span>
                      <div className="info-planos-tratamento">
                        <span className="big-numbers">
                          {avaliacoesPorEfetuar.length}
                        </span>
                        <span className="medium-gray-text">por efetuar</span>
                      </div>
                      <div
                        className="button-more-div"
                        onClick={handleOpen.bind(null, "avaliacao")}
                      >
                        <span className="button-more">Mais</span>
                        <span className="more-arrow"> {">"} </span>
                      </div>
                    </div>
                    {urgencias && (
                      <div className="dashboard__right__top-right">
                        <span
                          className={`box-title ${
                            urgencias.length > 0 && `urgencia`
                          }`}
                        >
                          Pacientes de Urgência
                        </span>
                        <span
                          className={`big-numbers ${
                            urgencias.length > 0 && `urgencia`
                          }`}
                        >
                          {urgencias.length}
                        </span>
                        <div
                          className="button-more-div"
                          onClick={handleOpen.bind(null, "urgencia")}
                        >
                          <span
                            className={`button-more ${
                              urgencias.length > 0 && `urgencia`
                            }`}
                          >
                            Mais
                          </span>
                          <span
                            className={`more-arrow ${
                              urgencias.length > 0 && `more-arrow--urgencia`
                            }`}
                          >
                            {">"}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  <hr />
                  <InformacoesDaClinica
                    sendRequest={sendRequest}
                    auth={auth}
                    clinica={clinica}
                  />
                </div>
              </div>

              <div className="listContainer caixa">
                <Procedimentos
                  visualizarPdf={visualizarPdf}
                  listaPresencaProp={listaPresenca}
                />
              </div>

              <div className="dashboard__bottom caixa tasks__container">
                <Tasks
                  clinica={clinica}
                  auth={auth}
                  sendRequest={sendRequest}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default HomeDentista;
