import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { generateExcelFile } from "../../../shared/components/excelUtils/exportExcel";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import toast from "react-hot-toast";
import dataGridData from "./components/datagridData";
import { useLoading } from "../../../shared/context/LoadingContext";
//Modais
import ModalVerEntrada from "./components/modalEntrada/ModalVerEntrada";
import ModalEntrada from "./components/modalEntrada/ModalEntrada";
import ModalEditarEntrada from "./components/modalEntrada/ModalEditarEntrada";
import ModalProcedimentos from "../../clientes/single/components/Debitos/components/ModalProcedimentos/ModalProcedimentos";
//ICONS
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ErrorIcon from "@mui/icons-material/Error";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
//Antd
import { Popover } from "antd";
import { Popconfirm } from "antd";
import { DataGrid } from "@mui/x-data-grid";
import FiltrosReceitas from "./components/filtrosReceitas/FiltrosReceitas";

//MUI
import Button from "@mui/material/Button";
import ModalReceberEntrada from "./components/modalEntrada/ModalReceberEntrada";
import ModalMotivoCancelamentoEntradas from "./components/ModalMotivoCancelamentoEntradas/ModalMotivoCancelamentoEntradas";

function Receitas({
  clinicaId,
  auth,
  showFatura,
  showRce,
  dadosClinica,
  dadosRecibo,
}) {
  const { startLoading, stopLoading } = useLoading();

  const { sendRequest } = useHttpClient();
  const [rows, setRows] = useState([]);
  const [modalData, setModalData] = useState({});
  const [contasCorrentes, setContasCorrentes] = useState();
  const [contasCorrentesAbsolut, setContasCorrentesAbsolut] = useState([]);
  const [contasCorrentesParaTextSearch, setContasCorrentesParaTextSearch] =
    useState([]);
  const [entradas, setEntradas] = useState([]);
  const [entradasAbsolut, setEntradasAbsolut] = useState([]);
  const [entradasParaTextSearch, setEntradasParaTextSearch] = useState([]);
  const [entradaId, setEntradaId] = useState();
  const [openModal, setOpenModal] = useState("");
  const [filtrosAplicados, setFiltrosAplicados] = useState([]);
  const [filtroCaixa, setFiltroCaixa] = useState();
  const [filtroCategoria, setFiltroCategoria] = useState();
  const [filtroMetodoPagamento, setFiltroMetodoPagamento] = useState();
  const [filtroTextoSearch, setFiltroTextoSearch] = useState("");
  const [
    dadosModalMotivoCancelamentoEntradas,
    setDadosModalMotivoCancelamentoEntradas,
  ] = useState({
    open: false,
  });

  const [financialData, setFinancialData] = useState({
    totalRecebido: 0,
    totalReceber: 0,
    totalPrevistoReceber: 0,
    saldo: 0,
    saldoTotalPrevisto: 0,
  });

  const fetchContasCorrentesEntrada = useCallback(
    async (startDate, endDate) => {
      startLoading();
      try {
        const formData = new FormData();
        formData.append("startDate", startDate);
        formData.append("endDate", endDate);

        const requests = [];
        let responseDataCc = { contasCorrentes: [] };
        let responseDataEntradas = { entradas: [] };

        if (auth.perm.includes("r-rec")) {
          requests.push(
            sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/financeiro/daterange/${clinicaId}`,
              "PATCH",
              formData,
              { Authorization: "Bearer " + auth.token }
            )
          );
        }

        if (auth.perm.includes("r-ent")) {
          requests.push(
            sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/clinica/daterange/${clinicaId}`,
              "PATCH",
              formData,
              { Authorization: "Bearer " + auth.token }
            )
          );
        }

        const responses = await Promise.all(requests);

        if (auth.perm.includes("r-rec")) {
          responseDataCc = responses.shift();
        }

        if (auth.perm.includes("r-ent")) {
          responseDataEntradas = responses.shift();
        }

        setContasCorrentes(responseDataCc.contasCorrentes);
        setContasCorrentesAbsolut(responseDataCc.contasCorrentes);
        setEntradas(responseDataEntradas.entradas);
        setEntradasAbsolut(responseDataEntradas.entradas);
        setContasCorrentesParaTextSearch(responseDataCc.contasCorrentes);
        setEntradasParaTextSearch(responseDataEntradas.entradas);

        handleFiltrar(
          responseDataCc.contasCorrentes,
          responseDataEntradas.entradas,
          [],
          null,
          null,
          null,
          "",
          {
            dataInicial: moment(startDate).format("DD/MM/YYYY"),
            dataFinal: moment(endDate).format("DD/MM/YYYY"),
          }
        );
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    },
    [auth.perm, auth.token, clinicaId, sendRequest]
  );

  useEffect(() => {
    fetchContasCorrentesEntrada(
      moment().startOf("day").toISOString(),
      moment().endOf("day").toISOString()
    );
  }, []);

  useEffect(() => {
    let dataParaDataGrid = [];
    let recebido = 0;
    let porReceber = 0;

    if (contasCorrentes) {
      contasCorrentes.forEach((contaCorrente) => {
        contaCorrente.contas.forEach((conta) => {
          //A proxima linha de codigo já não é necessária pois filtro
          //contas que não estão aprovadas na backend
          conta.parcelas.forEach((parcela) => {
            recebido += parcela.quantiaPaga;
            if (parcela.status !== "CANCELADA") {
              porReceber += parcela.quantiaPorPagar;
            }
            dataParaDataGrid.push({
              tipo: "receita",
              cliente: contaCorrente.cliente.nome,
              idCliente: contaCorrente.cliente._id,
              dadosCliente: contaCorrente.cliente,
              idContaCorrente: contaCorrente._id,
              orcamento: conta.orcamento,
              id: parcela._id,
              contaId: conta._id,
              dataLimitePagamento: parcela.dataLimitePagamento,
              dataPagamento: parcela.dataPagamento,
              quantiaPorPagar: parcela.quantiaPorPagar,
              quantiaPaga: parcela.quantiaPaga,
              metodoPagamento: parcela.metodoPagamento,
              entrada: parcela.entrada,
              nrParcela: parcela.nrParcela,
              parcelaPaga: parcela.parcelaPaga,
              estado: parcela.parcelaPaga
                ? "Pago"
                : moment()
                    .startOf("day")
                    .isAfter(moment(parcela.dataLimitePagamento).startOf("day"))
                ? "Em atraso"
                : "",
              documentoEletronico: parcela.documentoEletronico,
              nrRecibo: parcela.nrRecibo,
              cancelamentosDocumentosEletronicos:
                parcela.cancelamentosDocumentosEletronicos,
              categoria: parcela.categoria?.categoria,
              caixa: parcela.caixa?.caixa,
              status: parcela.status,
              motivoEncerramento: conta.motivoEncerramento,
            });
          });
        });
      });
    }

    if (entradas && entradas.length > 0) {
      entradas.forEach((entrada) => {
        if (entrada.entradaRecebida && entrada.valor) {
          recebido += entrada.valor;
        } else {
          porReceber += entrada.valor;
        }
        dataParaDataGrid.push({
          tipo: "entrada",
          tipoEntrada: entrada.tipo,
          id: entrada.id,
          cliente: entrada.clienteFornecedor
            ? entrada.clienteFornecedor
            : entrada.cliente,
          dataPagamento: entrada.dataPagamento,
          dataLimitePagamento: entrada.dataLimitePagamento,
          valor: entrada.valor,
          metodoPagamento: entrada.metodoPagamento,
          descricao: entrada.descricao,
          comprovativo: entrada.comprovativo,
          nrRecibo: entrada.nrRecibo,
          documentoEletronico: entrada.documentoEletronico,
          entradaRecebida: entrada.entradaRecebida,
          faturaEReciboEletronicos: entrada.faturaEReciboEletronicos,
          cancelamentosDocumentosEletronicos:
            entrada.cancelamentosDocumentosEletronicos,
          categoria: entrada.categoria?.categoria,
          caixa: entrada.caixa?.caixa,
        });
      });
    }

    dataParaDataGrid = dataParaDataGrid.sort(
      (a, b) =>
        new Date(a.dataPagamento ? a.dataPagamento : a.dataLimitePagamento) -
        new Date(b.dataPagamento ? b.dataPagamento : b.dataLimitePagamento)
    );

    setFinancialData({
      totalRecebido: recebido.toLocaleString("pt-BR"),
      totalReceber: porReceber.toLocaleString("pt-BR"),
    });

    setRows(dataGridData(dataParaDataGrid));
  }, [contasCorrentes, entradas]);

  //A funcao verifica o nif e compara com o nome retornado para ver se é identico
  const checkNifError = async (nif, nome, nomePais) => {
    let erro;
    if (nomePais !== "CABO VERDE") {
      erro = false;
    } else {
      const formData = new FormData();
      nif && formData.append("nif", nif);

      formData.append(
        "clienteSecret",
        dadosClinica.dadosEmissor.cliente_oauth_secret
      );
      formData.append("clienteId", dadosClinica.dadosEmissor.cliente_id);

      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/nifbinome`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        if (response.payload.length === 0 || response.payload.length > 1) {
          erro = true;
        } else if (response.payload.length === 1) {
          if (response.payload[0].Name.toLowerCase() === nome.toLowerCase())
            erro = false;
          else erro = true;
        }
      } catch (err) {
        console.error("err", err);
      }
    }
    return erro;
  };

  const handleCloseModal = useCallback(() => {
    setOpenModal("");
    setModalData("");
    setDadosModalMotivoCancelamentoEntradas({});
  }, []);

  const desativarEntradaHandler = async (id) => {
    startLoading();
    const filteredEntrada = entradas.find((e) => e._id === id);
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/desativar/${id}`,
        "PATCH",
        null,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      //Se a entrada for referente à cobrança de uma avaliação, essa avaliação deixa de ser debitada
      if (filteredEntrada.avaliacao) {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/avaliacoes/removerdebitodeavaliacao/${filteredEntrada.avaliacao}`,
          "PATCH",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      }

      const tempEntradas = [...entradas];
      const tempEntradasAbsolut = [...entradasAbsolut];
      const tempEntradasParaTextSearch = [...entradasParaTextSearch];

      const indexEntrada = tempEntradas.findIndex(
        (entrada) => entrada._id === id
      );

      const indexEntradaAbsolut = tempEntradasAbsolut.findIndex(
        (entrada) => entrada._id === id
      );

      const indexEntradaParaTextSearch = tempEntradasParaTextSearch.findIndex(
        (entrada) => entrada._id === id
      );

      tempEntradas.splice(indexEntrada, 1);
      tempEntradasAbsolut.splice(indexEntradaAbsolut, 1);
      tempEntradasParaTextSearch.splice(indexEntradaParaTextSearch, 1);
      setEntradas([...tempEntradas]);
      setEntradasAbsolut([...tempEntradasAbsolut]);
      setEntradasParaTextSearch([...tempEntradasParaTextSearch]);

      toast.success(
        `Entrada desativada com sucesso!${
          filteredEntrada.avaliacao
            ? " A avaliação volta ao estado não debitado."
            : ""
        }`
      );
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const verEntradaHandler = (id) => {
    const entradaFiltrada = entradas.filter((entr) => entr.id === id)[0];
    setModalData(entradaFiltrada);
    setOpenModal("verEntrada");
  };

  const editarEntradaHandler = (id) => {
    setEntradaId(id);
    setOpenModal("editarEntrada");
  };

  const handleOpenModalProcedimentos = (orcamento) => {
    setModalData(orcamento);
    setOpenModal("procedimentos");
  };

  const handleOpenModalReceberEntrada = (id) => {
    const entradaFiltada = entradas.filter((e) => e.id === id)[0];
    setModalData(entradaFiltada);
    setOpenModal("receberEntrada");
  };

  const handleBeforeCancelarRecebimentoEntrada = (
    id,
    enviarDocCancelamento
  ) => {
    const entradaFiltrada = entradas.filter((e) => e.id === id)[0];

    if (
      entradaFiltrada.tipo !== "reembolso" ||
      entradaFiltrada.documentoEletronico?.tipoDocumento === "TVE"
    ) {
      if (enviarDocCancelamento) {
        setDadosModalMotivoCancelamentoEntradas({
          open: "motivoCancelamentoEntradas",
          entrada: entradaFiltrada,
          tipoDocumento: "Event",
          tipoDocumentoAnular:
            entradaFiltrada.documentoEletronico?.tipoDocumento === "TVE"
              ? "TVE"
              : "RCE",
        });
      } else {
        handleCancelarRecebimentoEntrada(entradaFiltrada);
      }
    } else {
      if (enviarDocCancelamento) {
        setDadosModalMotivoCancelamentoEntradas({
          open: "motivoCancelamentoEntradas",
          entrada: entradaFiltrada,
          tipoDocumento: "NCE",
          tipoDocumentoAnular: "FRE",
        });
      } else {
        handleCancelarRecebimentoEntrada(entradaFiltrada);
      }
    }
  };

  const handleBeforeNCEEntrada = (id) => {
    const entradaFiltrada = entradas.filter((e) => e.id === id)[0];
    setDadosModalMotivoCancelamentoEntradas({
      open: "motivoCancelamentoEntradas",
      entrada: entradaFiltrada,
      tipoDocumento: "NCE",
      apenasEmitir: true,
      tipoDocumentoAnular: entradaFiltrada.documentoEletronico.tipoDocumento,
    });
  };

  const handleCancelarRecebimentoEntrada = async (
    entradaFiltrada,
    enviarDocCancelamento,
    motivoCancelamento,
    tipoDocumento
  ) => {
    startLoading();
    try {
      let novaEntrada = entradaFiltrada;
      let resposta;

      if (enviarDocCancelamento) {
        const formData = new FormData();
        formData.append("clinicaId", clinicaId);
        formData.append("usuarioEmissor", auth.userId);
        formData.append("entrada", entradaFiltrada._id);
        formData.append("valor", entradaFiltrada.valor);

        if (tipoDocumento !== "Event") {
          formData.append(
            "IUDCancelar",
            entradaFiltrada.documentoEletronico.entryName.replace(/\.xml$/, "")
          );
          formData.append(
            "IssueReasonCode",
            JSON.stringify(motivoCancelamento)
          );
          formData.append(
            "nomeCliente",
            entradaFiltrada.clienteFornecedor
              ? entradaFiltrada.clienteFornecedor.nome
              : entradaFiltrada.cliente.nomeFiscal
          );
          formData.append(
            "nifCliente",
            entradaFiltrada.clienteFornecedor
              ? entradaFiltrada.clienteFornecedor.nif
              : entradaFiltrada.cliente.nif
          );

          formData.append("descricaoPagamento", entradaFiltrada.descricao);

          formData.append(
            "contactoCliente",
            entradaFiltrada.clienteFornecedor
              ? entradaFiltrada.clienteFornecedor.contacto
              : entradaFiltrada.cliente.contacto
          );
          formData.append(
            "moradaCliente",
            entradaFiltrada.clienteFornecedor
              ? entradaFiltrada.clienteFornecedor.endereco
              : entradaFiltrada.cliente.endereco
          );
          formData.append(
            "codigoPaisCliente",
            entradaFiltrada.clienteFornecedor
              ? entradaFiltrada.clienteFornecedor.pais.codigo
              : entradaFiltrada.cliente.pais.codigo
          );
          formData.append("metodoPagamento", entradaFiltrada.metodoPagamento);

          formData.append("dataPagamento", entradaFiltrada.dataPagamento);

          formData.append("tipoDocEletronico", tipoDocumento);
          formData.append("online", true);
        }

        if (tipoDocumento !== "Event") {
          try {
            resposta = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/emitirdocumentoeletronico`,
              "POST",
              formData,
              {
                Authorization: "Bearer " + auth.token,
              }
            );

            // Atualizar o documento eletronico na entrada na base de dados
            const formData2 = new FormData();

            //Atualizar a array de cancelamentos de docmento eletronico
            formData2.append(
              "documentoCancelamento",
              resposta.respostaDocumentoEletronico._id
            );
            formData2.append(
              "documentoEletronico",
              entradaFiltrada.documentoEletronico._id
            );

            const tempNovaEntrada = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/aposcancelamentodoceletronico/${entradaFiltrada.id}`,
              "PATCH",
              formData2,
              {
                Authorization: "Bearer " + auth.token,
              }
            );

            if (tempNovaEntrada) {
              novaEntrada = tempNovaEntrada.entrada;
            }
          } catch (err) {
            console.error("err", err);
          }

          //CASO EMITIR EVENTO:
        } else {
          if (entradaFiltrada.documentoEletronico.tipoDocumento === "TVE") {
            formData.append(
              "idDocumentoAnular",
              entradaFiltrada.documentoEletronico._id
            );
            formData.append(
              "IUDCancelar",
              entradaFiltrada.documentoEletronico.entryName.replace(
                /\.xml$/,
                ""
              )
            );
          } else {
            formData.append(
              "idDocumentoAnular",
              entradaFiltrada.faturaEReciboEletronicos.rce._id
            );
            formData.append(
              "IUDCancelar",
              entradaFiltrada.faturaEReciboEletronicos.rce.entryName.replace(
                /\.xml$/,
                ""
              )
            );
          }
          formData.append("IssueReasonDescription", motivoCancelamento);
          try {
            resposta = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/emitirevento`,
              "POST",
              formData,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
            ////////////////////////////////////////////////////////
            // Atualizar o documento eletronico na entrada na base de dados
            const formData2 = new FormData();
            let tempNovaEntrada;
            //Atualizar a array de cancelamentos de docmento eletronico
            if (entradaFiltrada.documentoEletronico.tipoDocumento === "TVE") {
              formData2.append(
                "documentoCancelamento",
                resposta.respostaDocumentoEletronico._id
              );
              formData2.append(
                "documentoEletronico",
                entradaFiltrada.documentoEletronico._id
              );

              tempNovaEntrada = await sendRequest(
                `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/aposcancelamentodoceletronico/${entradaFiltrada.id}`,
                "PATCH",
                formData2,
                {
                  Authorization: "Bearer " + auth.token,
                }
              );
            } else {
              formData2.append(
                "documentoCancelamento",
                resposta.respostaDocumentoEletronico._id
              );
              formData2.append(
                "documentoEletronico",
                entradaFiltrada.faturaEReciboEletronicos.rce._id
              );

              tempNovaEntrada = await sendRequest(
                `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/aposenvioeventpararce/${entradaFiltrada.id}`,
                "PATCH",
                formData2,
                {
                  Authorization: "Bearer " + auth.token,
                }
              );
            }
            if (tempNovaEntrada?.entrada) {
              novaEntrada = tempNovaEntrada.entrada;
            }
            ////////////////////////////////////////////////////////
          } catch (err) {
            console.error("err", err);
          }
        }
        /////////////////////////////////////////////////////////////////////////
      }

      //Agora atualizamos o campo entrada recebida para false caso o envio do NCE Ocorrer ou caso não houver necessidade de enviar NCE ou Evento
      if (
        (enviarDocCancelamento &&
          resposta?.respostaEfatura?.responses[0]?.succeeded === true) ||
        !enviarDocCancelamento
      ) {
        let resultAnularRecebimento;
        try {
          const formDataCancelarRecebimento = new FormData();
          formDataCancelarRecebimento.append("canceladoPor", auth.userId);
          resultAnularRecebimento = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/anularRecebimento/${entradaFiltrada._id}`,
            "PATCH",
            formDataCancelarRecebimento,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }

        //Se anulação de pagamento
        if (resultAnularRecebimento?.entrada) {
          updateEntradas(resultAnularRecebimento.entrada, "atualizar");
          toast.success(
            `Recebimento revertido com sucesso ${
              resposta?.respostaEfatura?.responses[0]?.succeeded === true
                ? " e DFE cancelado!"
                : "!"
            }`
          );
        } else {
          //A entrada continua recebida
          updateEntradas(novaEntrada, "atualizar");
          toast.error("Erro no cancelamento do recebimento da entrada.");
        }
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };
  //DFE
  //--------------------------------------------------------------

  //Emitir FRE ou TVE para Entradas
  const emitirDocEletronicoReembolso = async (
    entrada,
    idEntrada,
    tipoDocumento,
    operacao
  ) => {
    startLoading();
    try {
      let entradaFiltrada;
      let idDocumentoAEliminar;
      if (entrada) {
        entradaFiltrada = entrada;
      } else {
        entradaFiltrada = entradas.filter((e) => e._id === idEntrada)[0];
      }

      if (!entradaFiltrada.cliente.nif && tipoDocumento !== "TVE") {
        toast.error("O cliente não possui um nif");
        updateEntradas(
          {
            ...entradaFiltrada,
          },
          operacao
        );
      } else if (
        (entradaFiltrada.cliente.nif === "000000000" ||
          entradaFiltrada.cliente.nif === "999999999") &&
        tipoDocumento === "FRE"
      ) {
        toast.error("Nifs 000000000 ou 999999999 não são válidos para FREs.");
        updateEntradas(
          {
            ...entradaFiltrada,
          },
          operacao
        );
      } else if (!entradaFiltrada.cliente.contacto) {
        toast.error("O cliente não possui um número de telefone");
        updateEntradas(
          {
            ...entradaFiltrada,
          },
          operacao
        );
      } else if (!entradaFiltrada.cliente.endereco) {
        toast.error("O cliente não possui um endereço");
        updateEntradas(
          {
            ...entradaFiltrada,
          },
          operacao
        );
      } else if (!entradaFiltrada.cliente.pais) {
        toast.error("O cliente não possui um pais");
        updateEntradas(
          {
            ...entradaFiltrada,
          },
          operacao
        );
      } else if (
        tipoDocumento === "TVE"
          ? false
          : await checkNifError(
              entradaFiltrada.cliente.nif,
              entradaFiltrada.cliente.nomeFiscal,
              entradaFiltrada.cliente.pais.nome
            )
      ) {
        toast.error("nif incomaptivel com o nome do cliente");
        updateEntradas(
          {
            ...entradaFiltrada,
          },
          operacao
        );
      } else {
        //SE existe 1 documento eletronico emitido com erro anteriormente
        if (
          (tipoDocumento === "FRE" || tipoDocumento === "TVE") &&
          entradaFiltrada.documentoEletronico?.succeeded === false
        ) {
          idDocumentoAEliminar = entradaFiltrada.documentoEletronico._id;
        }

        const formData = new FormData();
        formData.append("clinicaId", clinicaId);
        if (tipoDocumento !== "TVE") {
          formData.append("nomeCliente", entradaFiltrada.cliente.nomeFiscal);
          formData.append("nifCliente", entradaFiltrada.cliente.nif);
          formData.append("contactoCliente", entradaFiltrada.cliente.contacto);
          formData.append("moradaCliente", entradaFiltrada.cliente.endereco);
          formData.append(
            "codigoPaisCliente",
            entradaFiltrada.cliente.pais.codigo
          );
        }

        formData.append("valor", entradaFiltrada.valor);
        formData.append("descricaoPagamento", entradaFiltrada.descricao);
        formData.append("metodoPagamento", entradaFiltrada.metodoPagamento);
        formData.append("tipoDocEletronico", tipoDocumento);
        formData.append("usuarioEmissor", auth.userId);
        formData.append("entrada", entradaFiltrada._id);
        formData.append("online", true);

        let resposta;
        try {
          resposta = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/emitirdocumentoeletronico`,
            "POST",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }

        if (resposta && idDocumentoAEliminar) {
          try {
            const respostaEliminacao = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/${idDocumentoAEliminar}`,
              "DELETE",
              formData,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            console.error("err", err);
          }
        }

        const formData2 = new FormData();
        formData2.append(
          "documentoEletronico",
          resposta.respostaDocumentoEletronico._id
        );
        let novaEntrada;

        try {
          novaEntrada = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/documentoeletronico/${entradaFiltrada.id}`,
            "PATCH",
            formData2,
            {
              Authorization: "Bearer " + auth.token,
            }
          );

          updateEntradas(
            {
              ...entradaFiltrada,
              documentoEletronico: novaEntrada.entrada.documentoEletronico,
            },
            operacao
          );

          toast.success(`${tipoDocumento} emitido com sucesso.`);
        } catch (err) {
          console.error("err", err);
          toast.error(
            "Documento eletrónico emitido, mas não associado à entrada. Contacte a equipa de suporte para resolver o problema!"
          );
        }
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };
  //Emitir FTE ou NCE
  const emitirDocumentoFiscalParaEntradas = async (
    id,
    tipoDocumento,
    action,
    motivoCancelamento
  ) => {
    startLoading();
    try {
      let entradaFiltrada;
      let idDocumentoAEliminar;
      //Caso eu passe a entrada em vez do id
      if (id?._id) {
        entradaFiltrada = id;
      } else {
        entradaFiltrada = entradas.filter((e) => e.id === id)[0];
      }

      //SE existe 1 documento eletronico emitido com erro anteriormente
      if (
        tipoDocumento === "FTE" &&
        entradaFiltrada.documentoEletronico?.succeeded === false
      ) {
        idDocumentoAEliminar = entradaFiltrada.documentoEletronico._id;
      } else if (
        tipoDocumento === "NCE" &&
        entradaFiltrada.cancelamentosRecibosEletronicos[
          entradaFiltrada.cancelamentosRecibosEletronicos?.length - 1
        ]?.documentoCancelamento?.succeeded === false
      ) {
        idDocumentoAEliminar =
          entradaFiltrada.cancelamentosRecibosEletronicos[
            entradaFiltrada.cancelamentosRecibosEletronicos?.length - 1
          ].documentoCancelamento._id;
      }

      //Enviamos o dfe escolhid. NCE ou FTE. Os dados enviados dependem do tipo de documento escolhido
      const formData = new FormData();
      formData.append("clinicaId", clinicaId);
      formData.append("nifCliente", entradaFiltrada.clienteFornecedor.nif);
      formData.append("valor", entradaFiltrada.valor);
      formData.append("descricaoPagamento", entradaFiltrada.descricao);
      formData.append("nomeCliente", entradaFiltrada.clienteFornecedor.nome);
      formData.append(
        "contactoCliente",
        entradaFiltrada.clienteFornecedor.contacto
      );
      formData.append(
        "moradaCliente",
        entradaFiltrada.clienteFornecedor.endereco
      );
      formData.append(
        "codigoPaisCliente",
        entradaFiltrada.clienteFornecedor.pais.codigo
      );
      formData.append("metodoPagamento", entradaFiltrada.metodoPagamento);

      if (tipoDocumento !== "NCE")
        formData.append("dataPagamento", entradaFiltrada.dataPagamento);

      formData.append(
        "dataLimitePagamento",
        entradaFiltrada.dataLimitePagamento
      );

      formData.append("tipoDocEletronico", tipoDocumento);
      formData.append("usuarioEmissor", auth.userId);
      formData.append("entrada", entradaFiltrada._id);
      formData.append("online", true);

      if (tipoDocumento === "NCE") {
        formData.append(
          "IUDCancelar",
          entradaFiltrada.documentoEletronico.entryName.replace(/\.xml$/, "")
        );
        formData.append("IssueReasonCode", JSON.stringify(motivoCancelamento));
      }
      let resposta;
      try {
        resposta = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/emitirdocumentoeletronico`,
          "POST",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      //Atualizar a array de cancelamentos de docmento eletronico
      const formData2 = new FormData();
      if (tipoDocumento === "NCE") {
        try {
          formData2.append(
            "documentoCancelamento",
            resposta.respostaDocumentoEletronico._id
          );
          formData2.append(
            "documentoEletronico",
            entradaFiltrada.documentoEletronico._id
          );

          const novaEntrada = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/aposcancelamentodoceletronico/${entradaFiltrada.id}`,
            "PATCH",
            formData2,
            {
              Authorization: "Bearer " + auth.token,
            }
          );

          updateEntradas(
            {
              ...entradaFiltrada,
              documentoEletronico: null,
              cancelamentosDocumentosEletronicos:
                novaEntrada.entrada.cancelamentosDocumentosEletronicos,
            },
            "atualizar"
          );
        } catch (err) {
          console.error("err", err);
        }
      } else if (tipoDocumento === "FTE") {
        formData2.append(
          "documentoEletronico",
          resposta.respostaDocumentoEletronico._id
        );

        let novaEntrada;
        try {
          novaEntrada = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/documentoeletronico/${entradaFiltrada.id}`,
            "PATCH",
            formData2,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }

        //Eliminar o documento eletronico com erro anterior, caso ele exista
        if (action === "atualizar") {
          updateEntradas(
            {
              ...entradaFiltrada,
              documentoEletronico: novaEntrada.entrada.documentoEletronico,
            },
            "atualizar"
          );
        } else {
          updateEntradas(
            {
              ...entradaFiltrada,
              documentoEletronico: novaEntrada.entrada.documentoEletronico,
            },
            "inserir"
          );
        }
      }

      if (resposta && idDocumentoAEliminar) {
        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/${idDocumentoAEliminar}`,
            "DELETE",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }
      }

      if (resposta?.respostaEfatura?.responses[0]?.succeeded) {
        toast.success(tipoDocumento + " emitido com sucesso");
      } else {
        toast.error(
          "Erro ao emitir" +
            tipoDocumento +
            ". " +
            resposta?.respostaEfatura.responses[0].messages[0].description
        );
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  //Emitir RCE
  const emitirRCEHandler = async (idEntrada, receberEEmitir) => {
    startLoading();
    try {
      const entradaFiltrada = entradas.filter((en) => en._id === idEntrada)[0];
      const formData = new FormData();
      formData.append("clinicaId", clinicaId);
      formData.append("nifCliente", entradaFiltrada.clienteFornecedor.nif);
      formData.append("valor", entradaFiltrada.valor);
      formData.append("nomeCliente", entradaFiltrada.clienteFornecedor.nome);
      formData.append(
        "contactoCliente",
        entradaFiltrada.clienteFornecedor.contacto
      );
      formData.append(
        "moradaCliente",
        entradaFiltrada.clienteFornecedor.endereco
      );
      formData.append(
        "codigoPaisCliente",
        entradaFiltrada.clienteFornecedor.pais.codigo
      );
      formData.append("metodoPagamento", entradaFiltrada.metodoPagamento);
      formData.append("tipoDocEletronico", "RCE");
      formData.append("usuarioEmissor", auth.userId);
      formData.append("entrada", idEntrada);
      formData.append("online", true);

      formData.append(
        "IUDReceber",
        entradaFiltrada.documentoEletronico.entryName.replace(/\.xml$/, "")
      );

      formData.append(
        "innerDocumentNumber",
        entradaFiltrada.documentoEletronico.nrDocumento
      );
      let resposta;
      try {
        resposta = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/emitirdocumentoeletronico`,
          "POST",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      //Atualizar os dados da entrada
      const formData2 = new FormData();
      formData2.append("rce", resposta.respostaDocumentoEletronico._id);
      formData2.append("fte", entradaFiltrada.documentoEletronico._id);

      let novaEntrada;
      try {
        novaEntrada = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/aposenviorce/${entradaFiltrada.id}`,
          "PATCH",
          formData2,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      updateEntradas(novaEntrada.entrada, "atualizar");

      if (resposta?.respostaEfatura?.responses[0]?.succeeded) {
        toast.success(
          `${
            receberEEmitir ? "Entrada Recebida e " : ""
          } RCE Emitido com sucesso`
        );
      } else {
        toast.error(
          "Erro ao emitir RCE" +
            resposta?.respostaEfatura?.responses[0]?.messages[0]?.description
        );
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const gerarPdfFaturaEletronicaHandler = async (
    id,
    entradaOuReceita,
    outrosDados
  ) => {
    let dadosFiltrados;

    if (entradaOuReceita === "entrada") {
      dadosFiltrados = entradas.filter((e) => e.id === id)[0];
    } else if (entradaOuReceita === "receita") {
      const contaCorrenteFiltrada = contasCorrentes.filter(
        (c) => c._id === outrosDados.idContaCorrente
      )[0];
      const contaFiltrada = contaCorrenteFiltrada.contas.filter(
        (c) => c._id === outrosDados.contaId
      )[0];
      dadosFiltrados = contaFiltrada.parcelas.filter((p) => p._id === id)[0];
    }

    showFatura({
      show: true,
      clinica: dadosClinica,
      cliente: {
        nome:
          entradaOuReceita === "entrada"
            ? dadosFiltrados.tipo === "reembolso"
              ? dadosFiltrados.cliente.nomeFiscal
              : dadosFiltrados.clienteFornecedor.nome
            : outrosDados.dadosCliente.nomeFiscal,
        endereco:
          entradaOuReceita === "entrada"
            ? dadosFiltrados.tipo === "reembolso"
              ? dadosFiltrados.cliente.endereco
              : dadosFiltrados.clienteFornecedor.endereco
            : outrosDados.dadosCliente.endereco,
        contacto:
          entradaOuReceita === "entrada"
            ? dadosFiltrados.tipo === "reembolso"
              ? dadosFiltrados.cliente.contacto
              : dadosFiltrados.clienteFornecedor.contacto
            : outrosDados.dadosCliente.contacto,
      },
      metodoPagamento: dadosFiltrados.metodoPagamento,
      designacao:
        entradaOuReceita === "entrada"
          ? dadosFiltrados.descricao
          : "Tratamento Dentário",
      valor:
        entradaOuReceita === "entrada"
          ? dadosFiltrados.valor
          : dadosFiltrados.quantiaPaga,
      nrDocumento: dadosFiltrados.documentoEletronico.nrDocumento,
      dataDocumento: dadosFiltrados.documentoEletronico.authorizedDateTime,
      dataVencimento: dadosFiltrados.dataLimitePagamento,
      entryName: dadosFiltrados.documentoEletronico.entryName,
      serie: dadosFiltrados.documentoEletronico.serie,
      tipoDocumento: dadosFiltrados.documentoEletronico.tipoDocumento,
      usuarioEmissor: dadosFiltrados.documentoEletronico.usuarioEmissor?.name,
    });
  };

  const gerarPdfRceHandler = async (id) => {
    let entradaFiltrada;

    entradaFiltrada = entradas.filter((e) => e.id === id)[0];
    showRce({
      show: true,
      clinica: dadosClinica,
      cliente: {
        nome: entradaFiltrada.clienteFornecedor.nome,
        endereco: entradaFiltrada.clienteFornecedor.endereco,
        contacto: entradaFiltrada.clienteFornecedor.contacto,
      },
      metodoPagamento: entradaFiltrada.metodoPagamento,
      dataPagamento: entradaFiltrada.dataPagamento,
      designacao: entradaFiltrada.descricao,
      valor: entradaFiltrada.valor,
      nrDocumento: entradaFiltrada.faturaEReciboEletronicos.rce.nrDocumento,
      dataDocumento:
        entradaFiltrada.faturaEReciboEletronicos.rce.authorizedDateTime,
      entryName: entradaFiltrada.faturaEReciboEletronicos.rce.entryName,
      serie: entradaFiltrada.faturaEReciboEletronicos.rce.serie,
      tipoDocumento: entradaFiltrada.faturaEReciboEletronicos.rce.tipoDocumento,
      referencia: entradaFiltrada.faturaEReciboEletronicos.fte.entryName,
      usuarioEmissor:
        entradaFiltrada.faturaEReciboEletronicos.rce.usuarioEmissor?.name,
    });
  };

  const updateEntradas = (entradaRetornada, action, emit) => {
    if (emit === "emitirFTE") {
      emitirDocumentoFiscalParaEntradas(entradaRetornada, "FTE", action);
    } else if (emit === "emitirRCE") {
      emitirRCEHandler(entradaRetornada._id, true);
    } else if (action === "inserir") {
      //Inserir
      const tempEntradas = [...entradas];
      const tempEntradasAbsolut = [...entradasAbsolut];
      const tempEntradasParaTextSearch = [...entradasParaTextSearch];

      tempEntradas.push(entradaRetornada);
      tempEntradasAbsolut.push(entradaRetornada);
      tempEntradasParaTextSearch.push(entradaRetornada);

      setEntradasAbsolut([...tempEntradasAbsolut]);
      setEntradasParaTextSearch([...tempEntradasParaTextSearch]);
      setEntradas([...tempEntradas]);

      stopLoading();
    } else {
      //Atualizar
      let tempEntradasAbsolut;
      let tempEntradas;
      let tempEntradasParaTextSearch;

      tempEntradasAbsolut = entradasAbsolut.map((e) => {
        if (e._id === entradaRetornada._id) {
          return entradaRetornada;
        } else {
          return e;
        }
      });

      tempEntradasParaTextSearch = entradasParaTextSearch.map((e) => {
        if (e._id === entradaRetornada._id) {
          return entradaRetornada;
        } else {
          return e;
        }
      });

      tempEntradas = entradas.map((e) => {
        if (e._id === entradaRetornada._id) {
          return entradaRetornada;
        } else {
          return e;
        }
      });

      setEntradas([...tempEntradas]);
      setEntradasAbsolut([...tempEntradasAbsolut]);
      setEntradasParaTextSearch([...tempEntradasParaTextSearch]);
      stopLoading();
    }
  };

  const handleExportar = () => {
    const sheetData = rows.map((r) => {
      return {
        descricao: r.descricao
          ? r.descricao
          : `${r.nome}${r.nrParcela ? " - Parcela " + r.nrParcela : ""}`,
        cliente: r.cliente,
        valor: r.valor,
        tipo: r.tipo,
        estado: r.estado,
        data_Pagamento: r.dataPagamento
          ? moment(r.dataPagamento).format("DD-MM-YYYY")
          : "-",
        dataLimitePagamento: r.dataLimitePagamento
          ? moment(r.dataLimitePagamento).format("DD-MM-YYYY")
          : "-",
        metodoPagamento: r.metodoPagamento ? r.metodoPagamento : "-",
        categoria: r.categoria ? r.categoria : "-",
        caixa: r.caixa ? r.caixa : "-",
      };
    });

    generateExcelFile(
      `HistoricoReceitas ${moment().format("DD-MM-YYYY")}.xlsx`,
      "Receitas",
      sheetData
    );
  };

  const handleFiltrar = (
    newContasCorrentes,
    newEntradas,
    filtros,
    filtroCai,
    filtroCat,
    filtroMet,
    textoSearch,
    data
  ) => {
    if (newContasCorrentes) {
      setContasCorrentes([...newContasCorrentes]);
    }
    if (newEntradas) {
      setEntradas([...newEntradas]);
    }

    if (data === "modalFiltros") {
      setEntradasParaTextSearch([...newEntradas]);
      setContasCorrentesParaTextSearch([...newContasCorrentes]);
    }

    if (data !== "textSearch") {
      setFiltrosAplicados(filtros);
      setFiltroCaixa(filtroCai);
      setFiltroCategoria(filtroCat);
      setFiltroMetodoPagamento(filtroMet);
    }
    setFiltroTextoSearch(textoSearch);
  };

  const gerarReciboInternoEntradas = async (idEntrada) => {
    startLoading();
    try {
      const entradaFiltrada = entradas.filter((e) => e._id === idEntrada)[0];
      let entradaRetornada;
      let nrRecibo = 1;
      if (entradaFiltrada.nrRecibo) {
        nrRecibo = entradaFiltrada.nrRecibo;
        gerarPdfReciboHandler(
          entradaFiltrada,
          nrRecibo,
          entradaFiltrada.cliente
        );
      } else {
        let idDocumentoAEliminar;
        //SE existe 1 documento eletronico emitido com erro anteriormente
        if (entradaFiltrada.documentoEletronico?.succeeded === false) {
          idDocumentoAEliminar = entradaFiltrada.documentoEletronico._id;
        }
        try {
          const nrDocumentos = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/numeracaodocumentos/${dadosClinica._id}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );

          nrRecibo = nrDocumentos.numeracaoDocumento.numeracoes.recibo + 1;
        } catch (err) {
          console.error("err", err);
        }

        //Atualizar o numero de recibo
        const formData = new FormData();
        formData.append("valor", nrRecibo);
        formData.append("tipoDocumento", "recibo");
        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/numeracaodocumentos/${dadosClinica._id}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }

        try {
          const formData = new FormData();
          formData.append("nrRecibo", nrRecibo);

          //Na backend, documento eletronico é removido da entrada
          const tempEntradaRetornada = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/gerarrecibo/${idEntrada}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );

          entradaRetornada = tempEntradaRetornada.entrada;

          if (entradaRetornada && idDocumentoAEliminar) {
            try {
              await sendRequest(
                `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/${idDocumentoAEliminar}`,
                "DELETE",
                formData,
                {
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {
              console.error("err", err);
            }
          }

          toast.success("Recibo gerado com sucesso!");
          updateEntradas(entradaRetornada, "atualizar");
        } catch (err) {
          console.error("err", err);
        }
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const gerarPdfReciboHandler = async (
    parcelaOuEntradaFiltrada,
    nrRecibo,
    clienteData
  ) => {
    dadosRecibo({
      clinica: dadosClinica,
      cliente: clienteData,
      valor: parcelaOuEntradaFiltrada.valor
        ? parcelaOuEntradaFiltrada.valor
        : parcelaOuEntradaFiltrada.quantiaPaga,
      nrRecibo: nrRecibo,
    });
  };

  const actionColumn = [
    {
      field: "inOrOut",
      headerName: "",
      width: 30,
      renderCell: (params) => {
        return (
          <>
            {params.row.tipo === "receita" &&
              params.row.status !== "CANCELADA" && (
                <CallReceivedIcon className="financeiro__container__cabecalho__totais--icon_div--receita" />
              )}

            {params.row.tipo === "entrada" && (
              <CallReceivedIcon className="financeiro__container__cabecalho__totais--icon_div--receita" />
            )}
          </>
        );
      },
    },
    {
      field: "data",
      headerName: "Data",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params.row.tipo === "receita" && (
              <span
                className={
                  params.row.parcelaPaga &&
                  params.row.dataPagamento &&
                  params.row.status !== "CANCELADA"
                    ? "spanCellPago"
                    : !params.row.parcelaPaga &&
                      params.row.status !== "CANCELADA" &&
                      moment(params.row.dataLimitePagamento) < moment()
                    ? "spanCellAtraso"
                    : ""
                }
              >
                {params.row.parcelaPaga && params.row.dataPagamento
                  ? moment(params.row.dataPagamento).format("DD-MM-YYYY")
                  : moment(params.row.dataLimitePagamento).format("DD-MM-YYYY")}
              </span>
            )}

            {params.row.tipo === "entrada" && (
              <div
                className={
                  params.row.entradaRecebida && params.row.dataPagamento
                    ? "spanCellPago"
                    : !params.row.entradaRecebida &&
                      moment().isAfter(
                        moment(params.row.dataLimitePagamento),
                        "day"
                      )
                    ? "spanCellAtraso"
                    : ""
                }
              >
                {params.row.entradaRecebida && params.row.dataPagamento
                  ? moment(params.row.dataPagamento).format("DD-MM-YYYY")
                  : moment(params.row.dataLimitePagamento).format("DD-MM-YYYY")}
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 5,
      renderCell: (params) => {
        return (
          <>
            {params.row.tipo === "receita" && (
              <div className="cellDebitoNome">
                <span
                  className={
                    params.row.parcelaPaga &&
                    params.row.dataPagamento &&
                    params.row.status !== "CANCELADA"
                      ? "spanCellPago"
                      : !params.row.parcelaPaga &&
                        params.row.status !== "CANCELADA" &&
                        moment(params.row.dataLimitePagamento) < moment()
                      ? "spanCellAtraso"
                      : ""
                  }
                >
                  {params.row.status === "CANCELADA"
                    ? "PARCELA " + params.row.status + " - "
                    : params.row.nome}{" "}
                  <Link
                    to={`/clientes/${params.row.idCliente}-debitos`}
                    className="link-cliente-receita"
                  >
                    {" "}
                    {params.row.cliente}
                  </Link>
                </span>
                {params.row.nrParcela && (
                  <Tooltip
                    title={`Parcelado em ${params.row.nrParcela[2]} vezes`}
                  >
                    <IconButton>
                      <span className="nrParcela">{params.row.nrParcela}</span>
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Ver orçamento">
                  <IconButton
                    onClick={handleOpenModalProcedimentos.bind(
                      null,
                      params.row.orcamento
                    )}
                  >
                    <ErrorIcon className="muiIconVerOrcamento" />
                  </IconButton>
                </Tooltip>
              </div>
            )}

            {params.row.tipo === "entrada" && (
              <div
                className={
                  "link-cliente-receita " + params.row.entradaRecebida &&
                  params.row.dataPagamento
                    ? "spanCellPago"
                    : !params.row.entradaRecebida &&
                      moment().isAfter(
                        moment(params.row.dataLimitePagamento),
                        "day"
                      )
                    ? "spanCellAtraso"
                    : ""
                }
              >
                {params.row.descricao}
                {params.row.cliente && (
                  <>
                    {" - "}

                    {params.row.tipoEntrada === "reembolso" ? (
                      <Link
                        to={`/clientes/${params.row.cliente._id}-debitos`}
                        className="link-cliente-receita"
                      >
                        {" "}
                        {params.row.cliente.nome}
                      </Link>
                    ) : (
                      params.row.cliente.nome
                    )}
                  </>
                )}
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "estado",
      headerName: "Pagamento",
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            {params.row.tipo === "receita" &&
              params.row.orcamento.desconto ===
                params.row.orcamento.subtotal && (
                <span className="debitos__isento">Isenção</span>
              )}

            {params.row.tipo === "receita" &&
              params.row.orcamento.desconto !== params.row.orcamento.subtotal &&
              params.row.status !== "CANCELADA" && (
                <div className="cellDebitoEstado">
                  <span
                    className={`debitos__container__estado ${
                      params.row.estado === "Em atraso"
                        ? "debitos__container__estado--atraso"
                        : params.row.estado === "Pago"
                        ? "debitos__container__estado--pago"
                        : ""
                    }`}
                  >
                    {params.row.metodoPagamento
                      ? params.row.metodoPagamento
                      : params.row.estado}
                  </span>
                </div>
              )}

            {params.row.tipo === "entrada" && (
              <div className="cellDebitoEstado">
                <span
                  className={
                    "debitos__container__estado debitos__container__estado--pago"
                  }
                >
                  {params.row.metodoPagamento}
                </span>
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "valor",
      headerName: "Valor",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.tipo === "receita" &&
              params.row.orcamento.desconto !==
                params.row.orcamento.subtotal && (
                <div className="cellAction" style={{ marginLeft: "auto" }}>
                  <span
                    className={`debitos__container__valor ${
                      params.row.estado === "Em atraso" &&
                      params.row.status !== "CANCELADA"
                        ? "debitos__container__valor--atraso"
                        : params.row.estado === "Pago" &&
                          params.row.status !== "CANCELADA"
                        ? "debitos__container__valor--pago"
                        : ""
                    }`}
                  >
                    {params.row.valor}
                  </span>
                </div>
              )}

            {params.row.tipo === "entrada" && (
              <span
                className={
                  params.row.entradaRecebida && params.row.dataPagamento
                    ? "spanCellPago"
                    : !params.row.entradaRecebida &&
                      moment().isAfter(
                        moment(params.row.dataLimitePagamento),
                        "day"
                      )
                    ? "spanCellAtraso"
                    : ""
                }
                style={{ marginLeft: "auto" }}
              >
                {params.row.valor}
              </span>
            )}
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="cellDebitoStatus">
            {params.row.estado === "Pago" && (
              <span
                className={
                  params.row.orcamento?.desconto ===
                  params.row.orcamento?.subtotal
                    ? "debitos__container__receber debitos__container__receber--isento"
                    : "debitos__container__receber debitos__container__receber--pago"
                }
              >
                <Tooltip
                  title={
                    params.row.orcamento?.desconto ===
                    params.row.orcamento?.subtotal
                      ? "Parcela Isenta de Pagamento"
                      : "Parcela Paga"
                  }
                >
                  <CheckCircleIcon />
                </Tooltip>
              </span>
            )}

            {params.row.tipo === "entrada" && params.row.entradaRecebida && (
              <span className="debitos__container__receber debitos__container__receber--pago">
                <Tooltip title="Entrada Recebida">
                  <CheckCircleIcon />
                </Tooltip>
              </span>
            )}

            {params.row.nrRecibo && (
              <span className="debitos__container__receber debitos__container__receber--reciboInterno">
                <Tooltip title="Recibo Interno">
                  <ArticleOutlinedIcon />
                </Tooltip>
              </span>
            )}

            {params.row.documentoEletronico?.succeeded === true && (
              <Tooltip
                title={
                  <div style={{ whiteSpace: "pre-line" }}>
                    {`${params.row.documentoEletronico?.tipoDocumento} emitido.`}
                    {params.row?.cancelamentosDocumentosEletronicos?.length >
                      0 && (
                      <>
                        <br />
                        {`${params.row.cancelamentosDocumentosEletronicos.length} cancelamentos efetuados.`}
                        {params.row.cancelamentosDocumentosEletronicos.map(
                          (c, index) => (
                            <React.Fragment key={index}>
                              <br />
                              {c.documento.entryName.split(".")[0]}
                            </React.Fragment>
                          )
                        )}
                      </>
                    )}
                  </div>
                }
              >
                <div
                  className={`debitos__container__receber ${
                    params.row.documentoEletronico?.tipoDocumento === "FTE"
                      ? "debitos__container__receber--fte"
                      : "debitos__container__receber--pago"
                  }`}
                >
                  <ArticleOutlinedIcon />
                </div>
              </Tooltip>
            )}

            {params.row.documentoEletronico?.succeeded === false && (
              <span className="debitos__container__receber debitos__container__receber--atraso">
                <Tooltip
                  title={
                    <div style={{ whiteSpace: "pre-line" }}>
                      {`${params.row.documentoEletronico?.tipoDocumento} com erro.`}
                      {params.row?.documentoEletronico?.messages?.length >
                        0 && (
                        <>
                          {params.row.documentoEletronico.messages.map(
                            (m, index) => (
                              <React.Fragment key={index}>
                                <br />
                                <br />
                                {m.description}
                              </React.Fragment>
                            )
                          )}
                        </>
                      )}
                    </div>
                  }
                >
                  <ArticleOutlinedIcon sx={{ cursor: "help" }} />
                </Tooltip>
              </span>
            )}

            {params.row.faturaEReciboEletronicos?.rce?.succeeded === true && (
              <Tooltip title={`RCE emitido`}>
                <div className="debitos__container__receber debitos__container__receber--pago">
                  <ArticleOutlinedIcon />
                </div>
              </Tooltip>
            )}

            {params.row.faturaEReciboEletronicos?.rce?.succeeded === false && (
              <span className="debitos__container__receber debitos__container__receber--atraso">
                <Tooltip
                  title={
                    <div style={{ whiteSpace: "pre-line" }}>
                      {`RCE emitido com erro.`}
                      {params.row?.faturaEReciboEletronicos?.rce?.messages
                        ?.length > 0 && (
                        <>
                          {params.row.faturaEReciboEletronicos.rce.messages.map(
                            (m, index) => (
                              <React.Fragment key={index}>
                                <br />
                                <br />
                                {m.description}
                              </React.Fragment>
                            )
                          )}
                        </>
                      )}
                    </div>
                  }
                >
                  <ArticleOutlinedIcon sx={{ cursor: "help" }} />
                </Tooltip>
              </span>
            )}

            {params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
              params.row.cancelamentosDocumentosEletronicos[
                params.row.cancelamentosDocumentosEletronicos?.length - 1
              ]?.documentoCancelamento?.succeeded === true && (
                <span className="debitos__container__receber debitos__container__receber--pago">
                  <Tooltip
                    title={
                      <React.Fragment>
                        {params.row.cancelamentosDocumentosEletronicos?.length +
                          "cancelamentos efetuados."}
                        <br />
                        {params.row.cancelamentosDocumentosEletronicos.map(
                          (c, index) => (
                            <React.Fragment key={index}>
                              {index > 0 && <br />}
                              {c.documento.entryName.split(".")[0]}
                            </React.Fragment>
                          )
                        )}
                      </React.Fragment>
                    }
                  >
                    <ArticleOutlinedIcon />
                  </Tooltip>
                </span>
              )}

            {/* {params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
              params.row.cancelamentosDocumentosEletronicos[
                params.row.cancelamentosDocumentosEletronicos?.length - 1
              ]?.documentoCancelamento?.succeeded === false && (
                <span className="debitos__container__receber debitos__container__receber--atraso">
                  <Tooltip
                    title={
                      <React.Fragment>
                        {
                          params.row.cancelamentosDocumentosEletronicos[
                            params.row.cancelamentosDocumentosEletronicos
                              ?.length - 1
                          ]?.documentoCancelamento?.tipoDocumento
                        }{" "}
                        com erro:
                        <br />
                        {params.row.cancelamentosDocumentosEletronicos[
                          params.row.cancelamentosDocumentosEletronicos.length -
                            1
                        ].documentoCancelamento.messages.map((m, index) => (
                          <React.Fragment key={index}>
                            {index > 0 && (
                              <>
                                <br />
                                <br />
                              </>
                            )}
                            {m.description}
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    }
                  >
                    <ArticleOutlinedIcon sx={{ cursor: "help" }} />
                  </Tooltip>
                </span>
              )} */}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Ações",
      flex: 1,
      renderCell: (params) => {
        let content;
        if (params.row.tipo === "entrada") {
          content = (
            <div className="dots__menu__popup">
              {/* VER ENTRADA */}
              <div
                className="popOverMenu--option"
                onClick={verEntradaHandler.bind(null, params.row.id)}
              >
                <span>Ver</span>
                <RemoveRedEyeIcon className="popOverMenu--option__icon" />
              </div>
              {/* EDITAR ENTRADA - apenas entradas que não possuem documento fiscal, cujo envio resultou em erro ou que nao estao pagas, não pode editar um documento com um envio de nce com erro*/}
              {(!params.row.documentoEletronico ||
                params.row.documentoEletronico?.succeeded === false) &&
                !params.row.entradaRecebida &&
                (params.row.cancelamentosDocumentosEletronicos?.length === 0 ||
                  (params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
                    params.row.cancelamentosDocumentosEletronicos[
                      params.row.cancelamentosDocumentosEletronicos?.length - 1
                    ]?.documentoCancelamento?.succeeded === true)) &&
                auth.perm.includes("u-ent") && (
                  <div
                    className="popOverMenu--option"
                    onClick={editarEntradaHandler.bind(null, params.row.id)}
                  >
                    <span>Editar</span>
                    <EditIcon className="popOverMenu--option__icon" />
                  </div>
                )}

              {/* GERAR PDF FTE - se existe documento eletronico e foi bem sucedido */}
              {params.row.documentoEletronico?.succeeded && (
                <div
                  className="popOverMenu--option"
                  onClick={gerarPdfFaturaEletronicaHandler.bind(
                    null,
                    params.row.id,
                    "entrada"
                  )}
                >
                  <span>
                    Gerar PDF {params.row.documentoEletronico?.tipoDocumento}
                  </span>
                  <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                </div>
              )}

              {/* GERAR PDF RCE - se existe RCE e foi bem sucedido */}
              {params.row.faturaEReciboEletronicos?.rce?.succeeded &&
                params.row.tipoEntrada !== "reembolso" && (
                  <div
                    className="popOverMenu--option"
                    onClick={gerarPdfRceHandler.bind(
                      null,
                      params.row.id,
                      "entrada"
                    )}
                  >
                    <span>Gerar PDF RCE</span>
                    <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                  </div>
                )}

              {params.row.nrRecibo && (
                <div
                  className="popOverMenu--option"
                  onClick={gerarReciboInternoEntradas.bind(null, params.row.id)}
                >
                  <span>Gerar PDF Recibo Interno</span>
                  <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                </div>
              )}

              {/* EMITIR FTE - Não pode existir documento eletronico enviado com ou sem erro, não pode existir cancelamento com erro e a entrada não pode estar paga*/}
              {(!params.row.documentoEletronico ||
                params.row.documentoEletronico?.succeeded === false) &&
                (params.row.cancelamentosDocumentosEletronicos?.length === 0 ||
                  (params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
                    params.row.cancelamentosDocumentosEletronicos[
                      params.row.cancelamentosDocumentosEletronicos?.length - 1
                    ]?.documentoCancelamento?.succeeded === true)) &&
                !params.row.entradaRecebida &&
                params.row.tipoEntrada !== "reembolso" && (
                  <Popconfirm
                    title="Fatura Eletrónica - FTE"
                    description={`Confirma a emissão?`}
                    icon={<ArticleOutlinedIcon style={{ color: "green" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={emitirDocumentoFiscalParaEntradas.bind(
                      null,
                      params.row.id,
                      "FTE",
                      "atualizar"
                    )}
                  >
                    <div className="popOverMenu--option">
                      <span>Emitir FTE</span>
                      <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}

              {/*Emitir Fre para reembolsos*/}
              {(!params.row.documentoEletronico ||
                params.row.documentoEletronico?.succeeded === false) &&
                !params.row.nrRecibo &&
                (params.row.cancelamentosDocumentosEletronicos?.length === 0 ||
                  (params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
                    params.row.cancelamentosDocumentosEletronicos[
                      params.row.cancelamentosDocumentosEletronicos?.length - 1
                    ]?.documentoCancelamento?.succeeded === true)) &&
                params.row.entradaRecebida &&
                params.row.tipoEntrada === "reembolso" && (
                  <Popconfirm
                    title="Fatura/Recibo Eletrónico - FRE"
                    description={`Confirma a emissão?`}
                    icon={<ArticleOutlinedIcon style={{ color: "green" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={emitirDocEletronicoReembolso.bind(
                      null,
                      null,
                      params.row.id,
                      "FRE",
                      "atualizar"
                    )}
                  >
                    <div className="popOverMenu--option">
                      <span>Emitir FRE</span>
                      <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}

              {!params.row.nrRecibo &&
                params.row.metodoPagamento === "Dinheiro" &&
                (!params.row.documentoEletronico ||
                  params.row.documentoEletronico?.succeeded === false) &&
                (params.row.cancelamentosDocumentosEletronicos?.length === 0 ||
                  (params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
                    params.row.cancelamentosDocumentosEletronicos[
                      params.row.cancelamentosDocumentosEletronicos?.length - 1
                    ]?.documento?.succeeded === false)) &&
                params.row.tipoEntrada === "reembolso" && (
                  <Popconfirm
                    title="Recibo Interno"
                    description={`Pretende emitir um recibo Interno?`}
                    icon={<ErrorIcon style={{ color: "red" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={gerarReciboInternoEntradas.bind(
                      null,
                      params.row.id
                    )}
                  >
                    <div className="popOverMenu--option">
                      <span>Emitir Recibo Interno</span>
                      <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}

              {/*Emitir TVE para reembolsos*/}
              {(!params.row.documentoEletronico ||
                params.row.documentoEletronico?.succeeded === false) &&
                !params.row.nrRecibo &&
                (params.row.cancelamentosDocumentosEletronicos?.length === 0 ||
                  (params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
                    params.row.cancelamentosDocumentosEletronicos[
                      params.row.cancelamentosDocumentosEletronicos?.length - 1
                    ]?.documentoCancelamento?.succeeded === true)) &&
                params.row.entradaRecebida &&
                params.row.tipoEntrada === "reembolso" && (
                  <Popconfirm
                    title="Talão de Venda Eletrónico - TVE"
                    description={`Confirma a emissão do Talão de Venda?`}
                    icon={<ArticleOutlinedIcon style={{ color: "green" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={emitirDocEletronicoReembolso.bind(
                      null,
                      null,
                      params.row.id,
                      "TVE",
                      "atualizar"
                    )}
                  >
                    <div className="popOverMenu--option">
                      <span>Emitir TVE</span>
                      <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}

              {/* Emitir documentoCancelamento - apenas para ftes, caso o documento enviado obteve sucesso e se caso houver um envio de NCE, esse foi bem sucedido */}
              {!params.row.entradaRecebida &&
                params.row.documentoEletronico?.succeeded &&
                !params.row.nrRecibo &&
                !params.row.faturaEReciboEletronicos?.fte &&
                params.row.tipoEntrada !== "reembolso" && (
                  <Popconfirm
                    title="Nota de Crédito - NCE"
                    description={`Pretende cancelar a Fatura com a emissão de uma nota de crédito?`}
                    icon={<ErrorIcon style={{ color: "red" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={handleBeforeNCEEntrada.bind(null, params.row.id)}
                  >
                    <div className="popOverMenu--option">
                      <span>Anular Fatura</span>
                      <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}

              {/* Emitir RCE - se a entrada estiver recebida e não houver nenhum erro nem no documento fiscal, no recibos eletronicos. (PENSAR EM CASO DE ERRO NO CANCELAMENTO) */}
              {params.row.entradaRecebida &&
                params.row.tipoEntrada !== "reembolso" &&
                params.row.documentoEletronico?.succeeded &&
                (!params.row.faturaEReciboEletronicos?.rce ||
                  params.row.faturaEReciboEletronicos?.rce?.succeeded ===
                    false) && (
                  <Popconfirm
                    title="Recibo Eletrónico - RCE"
                    description={`Confirma emissão do Recibo Eletrónico?`}
                    icon={<ErrorIcon style={{ color: "green" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={emitirRCEHandler.bind(null, params.row.id, null)}
                  >
                    <div className="popOverMenu--option">
                      <span>Emitir RCE</span>
                      <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}

              {/* Comprovativo */}
              {params.row.comprovativo && params.row.comprovativo.ficheiro && (
                <a
                  href={`${process.env.REACT_APP_BACKEND_LINK}/${params.row.comprovativo.ficheiro}`}
                  download
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="popOverMenu--option">
                    <span>Baixar Comprovativo</span>
                    <DownloadIcon className="popOverMenu--option__icon" />
                  </div>
                </a>
              )}

              {/*CANCELAR RECEBIMENTO 1 - apenas para FTES, caso a entrada estiver paga, o documento enviado obteve sucesso e se caso houver um envio de NCE, esse foi bem sucedido */}
              {params.row.entradaRecebida && (
                <Popconfirm
                  title="Cancelar Recebimento"
                  description={`Pretende cancelar o recebimento da entrada - ${
                    params.row.descricao
                  } ${
                    params.row.documentoEletronico?.succeeded === true &&
                    params.row.tipoEntrada === "reembolso"
                      ? ` e cancelar a/o ${params.row?.documentoEletronico?.tipoDocumento}`
                      : params.row.faturaEReciboEletronicos?.rce?.succeeded ===
                        true
                      ? " e anular o Recibo Eletrónico"
                      : ""
                  }?`}
                  icon={<ErrorIcon style={{ color: "red" }} />}
                  okText="Sim"
                  cancelText="Não"
                  onConfirm={handleBeforeCancelarRecebimentoEntrada.bind(
                    null,
                    params.row.id,
                    (params.row.tipoEntrada === "reembolso" &&
                      params.row.documentoEletronico?.succeeded) ||
                      params.row.faturaEReciboEletronicos?.rce?.succeeded ===
                        true
                      ? true
                      : false //Enviar NCE caso houver um fte enviado
                  )}
                >
                  <div className="popOverMenu--option">
                    <span>Cancelar recebimento</span>
                    <SettingsBackupRestoreIcon className="popOverMenu--option__icon" />
                  </div>
                </Popconfirm>
              )}

              {/* DESATIVAR ENTRADA -  se não houver nenum tipo de documento fiscal com ou sem erro e a entrada não estiver recebida */}
              {!params.row.documentoEletronico?.succeeded &&
                (!params.row.cancelamentosDocumentosEletronicos ||
                  params.row.cancelamentosDocumentosEletronicos?.length ===
                    0) &&
                !params.row.faturaEReciboEletronicos?.fte &&
                !params.row.entradaRecebida &&
                auth.perm.includes("d-ent") && (
                  <Popconfirm
                    title="Desativar Entrada"
                    description={`Pretende desativar a entrada - ${params.row.descricao}?`}
                    icon={<ErrorIcon style={{ color: "red" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={desativarEntradaHandler.bind(
                      null,
                      params.row.id
                    )}
                  >
                    <div className="popOverMenu--option">
                      <span>Desativar</span>
                      <DeleteIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}
            </div>
          );
        }

        return (
          <>
            {/* RECEBER - Entradas nao pagas */}
            {params.row.tipo === "entrada" &&
              !params.row.entradaRecebida &&
              ((params.row.documentoEletronico?.succeeded &&
                params.row.tipoEntrada !== "reembolso") ||
                params.row.tipoEntrada === "reembolso") &&
              auth.perm.includes("rec-ent") && (
                <span
                  className={`debitos__container__receber ${
                    params.row.estado === "Em atraso" &&
                    "debitos__container__receber--atraso"
                  }`}
                  onClick={handleOpenModalReceberEntrada.bind(
                    null,
                    params.row.id
                  )}
                >
                  Receber
                </span>
              )}

            {content && (
              <Popover content={content} trigger="click" placement="bottom">
                <div className="dots__menu">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </Popover>
            )}
          </>
        );
      },
    },
  ];

  return (
    <div className="financeiro__container caixa">
      <div className="financeiro__adicionarEFiltros">
        <FiltrosReceitas
          contasCorrentesAbsolut={contasCorrentesAbsolut}
          entradasAbsolut={entradasAbsolut}
          entradasParaTextSearch={entradasParaTextSearch}
          contasCorrentesParaTextSearch={contasCorrentesParaTextSearch}
          handleFiltrar={handleFiltrar}
          handleExportar={handleExportar}
          filtrosAplicados={filtrosAplicados}
          filtroCaixa={filtroCaixa}
          filtroCategoria={filtroCategoria}
          filtroMetodoPagamento={filtroMetodoPagamento}
          filtroTextoSearch={filtroTextoSearch}
          clinicaId={clinicaId}
          auth={auth}
          fetchContasCorrentesEntrada={fetchContasCorrentesEntrada}
        />

        <div>
          {auth.perm.includes("c-ent") && (
            <Button
              variant="contained"
              color="success"
              id="basic-button"
              onClick={(e) => setOpenModal("entrada")}
            >
              + Adicionar Entrada
            </Button>
          )}
        </div>
      </div>

      <div className="financeiro__container__cabecalho">
        <div className="financeiro__container__cabecalho__totais">
          <div className="financeiro__container__cabecalho__totais--recebido">
            <div className="financeiro__container__cabecalho__totais__firstContainer financeiro__container__cabecalho__totais__firstContainer--recebido">
              <div className="financeiro__container__cabecalho__totais--icon_div">
                <span className="financeiro__container__cabecalho__totais--titulos">
                  Total Recebido
                </span>
              </div>
              <div className="financeiro__container__cabecalho__totais--texto">
                <span className="financeiro__container__cabecalho__totais--recebido--valor_principal">
                  {financialData.totalRecebido}$00
                </span>
              </div>
            </div>
          </div>

          <div className="financeiro__container__cabecalho__totais--saldo">
            <div className="financeiro__container__cabecalho__totais__firstContainer financeiro__container__cabecalho__totais__firstContainer--saldo">
              <span className="financeiro__container__cabecalho__totais--titulos">
                Total a Receber
              </span>
              <div className="financeiro__container__cabecalho__totais--texto">
                <span className="financeiro__container__cabecalho__totais--saldo--valor_principal">
                  {financialData.totalReceber}$00
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {rows && (
        <div style={{ height: 600, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={actionColumn}
            pageSize={10}
            rowsPerPageOptions={[10]}
          />
        </div>
      )}

      {openModal === "procedimentos" && (
        <ModalProcedimentos
          open={openModal === "procedimentos"}
          handleCloseModalProcedimentos={handleCloseModal}
          modalProcedimentosData={modalData}
        />
      )}

      {openModal === "verEntrada" && (
        <ModalVerEntrada
          openModalVerEntrada={openModal === "verEntrada"}
          handleCloseModalVerEntrada={handleCloseModal}
          dadosModalVerEntrada={modalData}
        />
      )}

      {openModal === "entrada" && (
        <ModalEntrada
          openModalEntrada={openModal === "entrada"}
          sendRequest={sendRequest}
          handleCloseModalEntrada={handleCloseModal}
          clinicaId={clinicaId}
          auth={auth}
          updateEntradas={updateEntradas}
          emitirDocEletronicoReembolso={emitirDocEletronicoReembolso}
        />
      )}

      {openModal === "editarEntrada" && (
        <ModalEditarEntrada
          openModalEditarEntrada={openModal === "editarEntrada"}
          sendRequest={sendRequest}
          handleCloseModalEditarEntrada={handleCloseModal}
          clinicaId={clinicaId}
          auth={auth}
          updateEntradas={updateEntradas}
          entradaId={entradaId}
          emitirDocEletronicoReembolso={emitirDocEletronicoReembolso}
        />
      )}

      {openModal === "receberEntrada" && (
        <ModalReceberEntrada
          openModalReceberEntrada={openModal === "receberEntrada"}
          sendRequest={sendRequest}
          handleCloseModalReceberEntrada={handleCloseModal}
          auth={auth}
          updateEntradas={updateEntradas}
          dadosModalReceberEntrada={modalData}
          clinicaId={clinicaId}
          emitirDocEletronicoReembolso={emitirDocEletronicoReembolso}
        />
      )}

      {dadosModalMotivoCancelamentoEntradas.open ===
        "motivoCancelamentoEntradas" && (
        <ModalMotivoCancelamentoEntradas
          open={
            dadosModalMotivoCancelamentoEntradas.open ===
            "motivoCancelamentoEntradas"
          }
          handleCloseModal={handleCloseModal}
          modalData={dadosModalMotivoCancelamentoEntradas}
          handleCancelarRecebimentoEntrada={handleCancelarRecebimentoEntrada}
          emitirDocumentoFiscalParaEntradas={emitirDocumentoFiscalParaEntradas}
        />
      )}
    </div>
  );
}

export default Receitas;
