import { useContext, useState } from "react";
import "./HomeAssistente.scss";

import Sidebar from "../../../shared/components/sidebar/Sidebar";
import Navbar from "../../../shared/components/navbar/Navbar";

import MarcacoesAssistente from "./components/marcacoesAssistente/MarcacoesAssistente";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { AuthContext } from "../../../shared/context/auth-context";
import { ClinicaContext } from "../../../shared/context/clinica-context";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import Tasks from "../components/tasks/Tasks";
import PdfListaAtendimentos from "../../../shared/components/PdfGenerator/PdfListaAtendimentos/PdfListaAtendimentos";

import "../components/top5tratamentos/Top5tratamentos.scss";
import "../../../shared/css/Checkbox.scss";

const DAILY_MESSAGES = [
  "Que seu dia seja tão produtivo quanto você fingiu ser na entrevista de emprego.",
  "Que seu dia seja mais tranquilo que sala de reunião vazia!",
  "Mantenha a postura de quem já recebeu o salário!",
  "Hoje você vai arrasar mais que funcionário em véspera de férias!",
  "Que seu dia seja mais próspero que conta bancária de patrão!",
  "Que hoje você tenha mais sorte que estagiário procurando café grátis!",
  "Seja mais positivo que avaliação de desempenho manipulada!",
  "Que seu dia seja mais agradável que ar condicionado no verão!",
  "Hoje você está mais importante que crachá de diretor!",
  "Que estejas mais animado que funcionário em dia de pagamento!",
  "Encare o dia com a confiança de quem tem backup de todos os arquivos!",
  "Hoje você está mais preparado que powerpoint de apresentação!",
  "Ques estejas mais determinado que estagiário querendo efetivação!",
  "Ques estejas mais relaxado que funcionário em home office!",
  "Que hoje você tenha mais sucesso que vírus em computador sem antivírus!",
  "Que seu dia seja mais feliz que o estagiário no dia do pagamento!",
  "Lembre-se que dormir no trabalho também é uma forma de meditação.",
  "Que seu dia seja mais organizado que sua mesa de trabalho!",
  "Que tenhas a energia de quem descobriu que amanhã é feriado!",
  "Hoje você está mais indispensável que café da manhã!",
  "Hoje você está mais essencial que post-it em monitor!",
  "Que seu dia seja mais próspero que hora extra em dezembro!",
  "Encare o trabalho como se fosse sexta-feira (mesmo que seja segunda)!",
  "Que seu dia seja mais produtivo que reunião sem o chefe!",
  "Ques tenhas o carisma de quem trouxe bolo pra equipe!",
  "Com a alegria de quem recebeu email de 'podem ir pra casa mais cedo'!",
  "Que seu dia seja mais criativo que desculpa pra sair mais cedo!",
  "Que tenhas a mesma energia do estagiário que faz o trabalho do gerente!",
  "Que tenhas a mesma vontade de trabalhar que político em fim de mandato!",
  "Mais um dia para fingir que gosta de acordar cedo!",
  "Que estejas mais motivado que funcionário em empresa falindo!",
];

function HomeAssistente() {
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);

  const [dadosPdf, setDadosPdf] = useState({
    show: false,
    listaAtendimentoData: [],
    medico: "",
  });

  const showSinglePage = () => {
    setDadosPdf({ show: false });
  };

  const verPdf = (medico, allMarcacoes, dataLista) => {
    const filteredMarcacoes = [];
    allMarcacoes.forEach((mr) => {
      if (mr.medico._id === medico.id) {
        filteredMarcacoes.push({
          cliente: mr.nome,
          contacto: mr.contacto,
          procedimento: mr.avaliacao
            ? "Avaliação"
            : mr.consulta
            ? "Consulta"
            : mr.categoriaTratamento.categoria,
          data_inicio_execucao: mr.data_inicio_execucao,
          data_fim_execucao: mr.data_fim_execucao,
        });
      }
    });

    setDadosPdf({
      show: true,
      listaAtendimentoData: filteredMarcacoes,
      medico: medico.nome,
      dataLista,
    });
  };
  ///////////////////////////////////////////////////
  return (
    <>
      {dadosPdf.show && (
        <PdfListaAtendimentos
          showPreviousPage={showSinglePage}
          medico={dadosPdf.medico}
          clinica={clinica.clinica.nome}
          listaAtendimentoData={dadosPdf.listaAtendimentoData}
          dataLista={dadosPdf.dataLista}
        />
      )}

      <div className="home">
        <Sidebar />
        <div className="homeContainer">
          <Navbar title="Dashboard" icon={DashboardIcon} />
          <div className="dashboard__secretaria__heading">
            <div className="dashboard__ola">
              <span className="dashboard__ola__big">{`Olá, ${auth.nome}!`}</span>
              <span className="dashboard__ola__small">
                {DAILY_MESSAGES[new Date().getDate() - 1]}
              </span>
            </div>
          </div>

          <div className="dashboard-administrativo__marcacoes caixa">
            <MarcacoesAssistente verPdf={verPdf} />
          </div>
          <div className="dashboard__bottom caixa tasks__container">
            <Tasks sendRequest={sendRequest} clinica={clinica} auth={auth} />
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeAssistente;
