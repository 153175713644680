import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../../shared/context/auth-context";
import "../List.scss";

//mui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete"; //Delete icon
import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined"; //Executar
import moment from "moment";
//Antd
import { Popover } from "antd";
import { Popconfirm } from "antd";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

function ListConsultas({ data, handleOpenModal, handleEliminar }) {
  const [consultas, setConsultas] = useState(data);
  const auth = useContext(AuthContext);
  useEffect(() => {
    setConsultas(data);
  }, [data]);

  const renderDeleteOption = (row) => {
    if (!row.executada && !row.faltou) {
      return (
        <Popconfirm
          title="Desativação da consulta"
          description="Tem a certeza que pretende desativar a consulta?"
          icon={<DeleteIcon style={{ color: "red" }} />}
          okText="Sim"
          cancelText="Não"
          onConfirm={() => handleEliminar(row._id)}
        >
          <div className="popOverMenu--option">
            <span>Eliminar</span>
            <DeleteIcon className="popOverMenu--option__icon" />
          </div>
        </Popconfirm>
      );
    }
    return null;
  };

  return (
    <>
      <TableContainer component={Paper} className="table">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {/* <TableCell className="tableCell tableCell--heading tableCell--heading--start">
                Histórico
              </TableCell> */}

              <TableCell className="tableCell tableCell--heading tableCell--heading--start">
                Número
              </TableCell>

              <TableCell className="tableCell tableCell--heading">
                Médico Responsável
              </TableCell>

              <TableCell className="tableCell tableCell--heading">
                Data Criação
              </TableCell>

              <TableCell className="tableCell tableCell--heading">
                Data Inicio
              </TableCell>
              <TableCell className="tableCell tableCell--heading">
                Data Fim
              </TableCell>

              <TableCell className="tableCell tableCell--heading">
                Estado
              </TableCell>

              <TableCell className="tableCell tableCell--heading tableCell--heading--end">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>

          {consultas && (
            <TableBody>
              {consultas.map((row) => (
                <TableRow key={row._id}>
                  <TableCell className="tableCell">
                    Consulta nr {row.numero}
                  </TableCell>

                  <TableCell className="tableCell">
                    {row.medico_responsavel.name}
                  </TableCell>

                  <TableCell className="tableCell">
                    {moment(row.created_at).format("DD-MM-YYYY")}
                  </TableCell>

                  <TableCell className="tableCell">
                    {row.data_inicio ? row.data_inicio : ""}
                  </TableCell>

                  <TableCell className="tableCell">
                    {row.data_fim ? row.data_fim : ""}
                  </TableCell>

                  <TableCell className="tableCell">
                    <span
                      className={
                        row.status
                          ? "status " + row.status
                          : row.executada
                          ? "status concluido"
                          : "status pendente"
                      }
                    >
                      {row.executada ? "Executada" : "Pendente"}
                    </span>
                  </TableCell>
                  <TableCell className="tableCell">
                    <div className="tableCell__action">
                      {auth.perm.includes("exec-plt") && !row.executada && (
                        <Tooltip title="Executar">
                          <PlaylistAddCheckOutlinedIcon
                            className="icon__list"
                            onClick={handleOpenModal.bind(
                              null,
                              "executar",
                              row._id
                            )}
                          />
                        </Tooltip>
                      )}

                      <Tooltip title="Ver Plano de Tratamento">
                        <RemoveRedEyeIcon
                          className="icon__list"
                          onClick={handleOpenModal.bind(null, "ver", row._id)}
                        />
                      </Tooltip>

                      {renderDeleteOption(row) && (
                        <Popover
                          content={renderDeleteOption(row)}
                          trigger="click"
                          placement="bottom"
                        >
                          <div className="dots__menu">
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                          </div>
                        </Popover>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
}

export default ListConsultas;
