import { useEffect, useState, useContext } from "react";
import "../categoriasFinanceiro/CategoriasFinanceiro.scss";
import { AuthContext } from "../../../shared/context/auth-context";
import { Popconfirm } from "antd";
import ErrorIcon from "@mui/icons-material/Error";
import { useLoading } from "../../../shared/context/LoadingContext";
import toast from "react-hot-toast";
import ModalConfiguracoesAvaliacao from "./components/modalConfiguracoesAvaliacao/ModalConfiguracoesAvaliacao";
import ModalConfiguracoesConsulta from "./components/modalConfiguracoesConsulta/ModalConfiguracoesConsulta";
import ModalConfiguracoesValidadeParcela from "./components/modalConfiguracoesValidadeParcela/ModalConfiguracoesValidadeParcela";

function Outras({ sendRequest, clinicaId }) {
  const { startLoading, stopLoading } = useLoading();

  const [modalData, setModalData] = useState();
  const [configuracao, setConfiguracao] = useState();
  const auth = useContext(AuthContext);

  useEffect(() => {
    const fetchConfiguracoes = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/configuracoes/clinica/${clinicaId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        setConfiguracao(responseData.configuracao);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchConfiguracoes();
  }, [auth.token, clinicaId, sendRequest]);

  const handleModalClose = () => {
    setModalData(null);
  };

  const handleOpenModal = (modal) => {
    setModalData({ open: modal, ...configuracao.configuracoes[modal] });
  };

  const handleAtivarDesativar = async (configuracao) => {
    startLoading();
    try {
      const formDataDesativar = new FormData();
      formDataDesativar.append("usuarioId", auth.userId);
      formDataDesativar.append("nomeConfiguracao", configuracao);

      const resposta = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/configuracoes/ativardesativar/${clinicaId}`,
        "PATCH",
        formDataDesativar,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      setConfiguracao(resposta.configuracao);
      toast.success(resposta.message);
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const handleUpdate = (novaConfiguracao) => {
    setConfiguracao({ ...novaConfiguracao });
  };

  return (
    <>
      <div className="categoriasGeral__container">
        {configuracao && (
          <>
            <div className="categoriasFinanceiro__container__categoria">
              {/* Avaliação */}
              <div className="categoriasFinanceiro__container__categoria--1">
                Avaliação
              </div>
              <div className="categoriasFinanceiro__container__categoria--2">
                <Popconfirm
                  title={`Desativar Caixa`}
                  description={`Pretende desativar a configuração de avaliação?`}
                  icon={<ErrorIcon style={{ color: "red" }} />}
                  okText="Sim"
                  cancelText="Não"
                  onConfirm={handleAtivarDesativar.bind(null, "avaliacao")}
                  className="popConfirm--biggerPadding"
                >
                  <span>
                    {configuracao?.configuracoes?.avaliacao?.ativo
                      ? "Desativar"
                      : "Ativar"}
                  </span>
                </Popconfirm>
                <span onClick={handleOpenModal.bind(null, "avaliacao")}>
                  Editar
                </span>
              </div>
            </div>

            <div className="categoriasFinanceiro__container__categoria">
              {/* Consulta */}
              <div className="categoriasFinanceiro__container__categoria--1">
                Consulta
              </div>
              <div className="categoriasFinanceiro__container__categoria--2">
                <Popconfirm
                  title={`Desativar Consulta`}
                  description={`Pretende desativar a configuração de consultas?`}
                  icon={<ErrorIcon style={{ color: "red" }} />}
                  okText="Sim"
                  cancelText="Não"
                  onConfirm={handleAtivarDesativar.bind(null, "consulta")}
                  className="popConfirm--biggerPadding"
                >
                  <span>
                    {configuracao?.configuracoes?.consulta?.ativo
                      ? "Desativar"
                      : "Ativar"}
                  </span>
                </Popconfirm>
                <span onClick={handleOpenModal.bind(null, "consulta")}>
                  Editar
                </span>
              </div>
            </div>

            <div className="categoriasFinanceiro__container__categoria">
              {/* Validade Parcelas */}
              <div className="categoriasFinanceiro__container__categoria--1">
                Validade de Parcelas
              </div>
              <div className="categoriasFinanceiro__container__categoria--2">
                <span onClick={handleOpenModal.bind(null, "validadeParcela")}>
                  Editar
                </span>
              </div>
            </div>
          </>
        )}
      </div>

      {modalData?.open === "avaliacao" && (
        <ModalConfiguracoesAvaliacao
          openModal={modalData.open === "avaliacao"}
          handleModalClose={handleModalClose}
          modalData={modalData}
          handleUpdate={handleUpdate}
          sendRequest={sendRequest}
          clinicaId={clinicaId}
          auth={auth}
        />
      )}

      {modalData?.open === "consulta" && (
        <ModalConfiguracoesConsulta
          openModal={modalData.open === "consulta"}
          handleModalClose={handleModalClose}
          modalData={modalData}
          handleUpdate={handleUpdate}
          sendRequest={sendRequest}
          clinicaId={clinicaId}
          auth={auth}
        />
      )}

      {modalData?.open === "validadeParcela" && (
        <ModalConfiguracoesValidadeParcela
          openModal={modalData.open === "validadeParcela"}
          handleModalClose={handleModalClose}
          modalData={modalData}
          handleUpdate={handleUpdate}
          sendRequest={sendRequest}
          clinicaId={clinicaId}
          auth={auth}
        />
      )}
    </>
  );
}

export default Outras;
