import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../shared/context/auth-context";
import { ClinicaContext } from "../../shared/context/clinica-context";
import Sidebar from "../../shared/components/sidebar/Sidebar";
import Navbar from "../../shared/components/navbar/Navbar";
import { useHttpClient } from "../../shared/hooks/http-hook";
import PdfFaturaWrapper from "../../shared/components/PdfGenerator/PdfFatura/PdfFaturaWrapper";
import PdfRceWrapper from "../../shared/components/PdfGenerator/PdfRce/PdfRceWrapper";
//MUI
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CallReceivedIcon from "@mui/icons-material/CallReceived";

import "./Financeiro.scss";

import Despesas from "./despesas/Despesas";
import FluxoCaixa from "./fluxoCaixa/FluxoCaixa";
import Receitas from "./receitas/Receitas";
import PdfReciboWrapper from "../../shared/components/PdfGenerator/PdfRecibo/PdfReciboWrapper";
import DfesPorEmitir from "./dfesPorEmitir/DfesPorEmitir";

function Financeiro() {
  const [showMainTabs, setShowMainTabs] = useState();
  const [showPdfFatura, setShowPdfFatura] = useState(false);
  const [faturaData, setFaturaData] = useState({});
  const [reciboData, setReciboData] = useState({ show: false });

  const [dadosClinica, setDadosClinica] = useState();
  const [dadosFatura, setDadosFatura] = useState({ show: false });
  const [dadosRce, setDadosRce] = useState({ show: false });

  const { sendRequest } = useHttpClient();

  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);

  useEffect(() => {
    const fetchDadosClinica = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/clinicas/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setDadosClinica(responseData.clinica);
      } catch (err) {
        console.error(err);
      }
    };

    fetchDadosClinica();
  }, [auth.token, clinica, sendRequest]);

  useEffect(() => {
    const abaAMostrar = auth.perm.includes("r-rec")
      ? "receitas"
      : auth.perm.includes("r-des")
      ? "despesas"
      : auth.perm.includes("r-flxcx")
      ? "fluxoCaixa"
      : "dfesPorEmitir";

    setShowMainTabs(abaAMostrar);
  }, []);

  const showFatura = (dadosFatura) => {
    setDadosFatura(dadosFatura);
  };

  const showRce = (dadosRce) => {
    setDadosRce(dadosRce);
  };

  const dadosRecibo = (dados) => {
    setReciboData({ ...dados, show: true });
  };

  const showPreviousPage = () => {
    if (dadosRce.show) {
      setDadosRce({ show: false });
    }

    if (reciboData.show) {
      setReciboData({ show: false });
    }

    if (showPdfFatura) {
      setShowPdfFatura(false);
    }
    if (dadosFatura.show) {
      setDadosFatura({ show: false });
    }
    if (faturaData) {
      setFaturaData({});
    }
  };

  return (
    <>
      {showPdfFatura && (
        <PdfFaturaWrapper
          showPreviousPage={showPreviousPage}
          faturaData={faturaData}
        />
      )}

      {dadosFatura.show && (
        <PdfFaturaWrapper
          showPreviousPage={showPreviousPage}
          faturaData={dadosFatura}
        />
      )}

      {dadosRce.show && (
        <PdfRceWrapper
          showPreviousPage={showPreviousPage}
          reciboData={dadosRce}
        />
      )}

      {reciboData.show && (
        <PdfReciboWrapper
          showPreviousPage={showPreviousPage}
          reciboData={reciboData}
        />
      )}

      {!showPdfFatura &&
        !dadosFatura.show &&
        !reciboData.show &&
        !dadosRce.show && (
          <div className="new">
            <Sidebar />
            <div className="newContainer">
              <Navbar title="Financeiro" icon={AttachMoneyIcon} />

              <div className="single__abas-principais single__abas-principais--financeiro">
                {(auth.perm.includes("r-rec") ||
                  auth.perm.includes("r-ent")) && (
                  <span
                    className={`single__abas-principais__aba ${
                      showMainTabs === "receitas" &&
                      "single__abas-principais__aba--active"
                    }`}
                    onClick={() => setShowMainTabs("receitas")}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    Receitas
                    <CallReceivedIcon style={{ color: "rgb(116, 169, 37)" }} />
                  </span>
                )}

                {auth.perm.includes("r-des") && (
                  <span
                    className={`single__abas-principais__aba ${
                      showMainTabs === "despesas" &&
                      "single__abas-principais__aba--active"
                    }`}
                    onClick={() => setShowMainTabs("despesas")}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    Despesas <CallMadeIcon style={{ color: "crimson" }} />
                  </span>
                )}

                {auth.perm.includes("r-flxcx") && (
                  <span
                    className={`single__abas-principais__aba ${
                      showMainTabs === "fluxoCaixa" &&
                      "single__abas-principais__aba--active"
                    }`}
                    onClick={() => setShowMainTabs("fluxoCaixa")}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    Fluxo de Caixa
                  </span>
                )}

                {auth.perm.includes("r-dfe") && (
                  <span
                    className={`single__abas-principais__aba ${
                      showMainTabs === "dfesPorEmitir" &&
                      "single__abas-principais__aba--active"
                    }`}
                    onClick={() => setShowMainTabs("dfesPorEmitir")}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    DFEs Por Emitir
                  </span>
                )}
              </div>

              {showMainTabs === "receitas" && dadosClinica && (
                <Receitas
                  clinicaId={clinica.clinica._id}
                  auth={auth}
                  showFatura={showFatura}
                  showRce={showRce}
                  dadosClinica={dadosClinica}
                  dadosRecibo={dadosRecibo}
                />
              )}
              {showMainTabs === "despesas" && dadosClinica && (
                <Despesas clinicaId={clinica.clinica._id} auth={auth} />
              )}
              {showMainTabs === "fluxoCaixa" && dadosClinica && (
                <FluxoCaixa clinicaId={clinica.clinica._id} auth={auth} />
              )}
              {showMainTabs === "dfesPorEmitir" && (
                <DfesPorEmitir clinicaId={clinica.clinica._id} auth={auth} />
              )}
            </div>
          </div>
        )}
    </>
  );
}

export default Financeiro;
