import React, { useContext, useState } from "react";
import { ClinicaContext } from "../../shared/context/clinica-context";
import { AuthContext } from "../../shared/context/auth-context";
import Sidebar from "../../shared/components/sidebar/Sidebar";
import Navbar from "../../shared/components/navbar/Navbar";
import BarChartIcon from "@mui/icons-material/BarChart";
import "./Relatorios.scss";
import PdfMapaFinanceiroWrapper from "../../shared/components/PdfGenerator/PdfMapaFinanceiro/PdfOrcamentoParticular/PdfMapaFinanceiroWrapper";

import RelatoriosFinanceiro from "./components/relatoriosFinanceiro/RelatoriosFinanceiro";
import RelatoriosAgendamentos from "./components/relatoriosAgendamentos/RelatoriosAgendamentos";
import RelatoriosOrcamentos from "./components/relatoriosOrcamentos/RelatoriosOrcamentos";

function Relatorios() {
  const clinica = useContext(ClinicaContext);
  const auth = useContext(AuthContext);
  const [showMainTabs, setShowMainTabs] = useState("financeiro");
  const [mapaData, setMapaData] = useState({ show: false });

  const dadosMapa = (dados, clinicaData, dataInicial, dataFinal) => {
    setMapaData({
      dados: dados,
      clinicaData: clinicaData,
      dataInicial,
      dataFinal,
      show: true,
    });
  };

  const showPreviousPage = () => {
    if (mapaData.show) {
      setMapaData({ show: false });
    }
  };

  return (
    <>
      {mapaData.show && (
        <PdfMapaFinanceiroWrapper
          showSinglePage={showPreviousPage}
          mapaData={mapaData}
        />
      )}

      {!mapaData.show && (
        <div className="new">
          <Sidebar />
          <div className="newContainer">
            <Navbar title="Relatórios" icon={BarChartIcon} />
            <div>
              <div className="single__abas-principais single__abas-principais--relatorio">
                <span
                  className={`single__abas-principais__aba ${
                    showMainTabs === "financeiro" &&
                    "single__abas-principais__aba--active"
                  }`}
                  onClick={() => setShowMainTabs("financeiro")}
                >
                  Financeiro
                </span>

                <span
                  className={`single__abas-principais__aba ${
                    showMainTabs === "orcamentos" &&
                    "single__abas-principais__aba--active"
                  }`}
                  onClick={() => setShowMainTabs("orcamentos")}
                >
                  Orcamentos
                </span>

                <span
                  className={`single__abas-principais__aba ${
                    showMainTabs === "clientes" &&
                    "single__abas-principais__aba--active"
                  }`}
                  onClick={() => setShowMainTabs("clientes")}
                >
                  Clientes
                </span>

                {(auth.role === "Médico/Administrador" ||
                  auth.role === "Administrador Não Médico") && (
                  <span
                    className={`single__abas-principais__aba ${
                      showMainTabs === "agendamentos" &&
                      "single__abas-principais__aba--active"
                    }`}
                    onClick={() => setShowMainTabs("agendamentos")}
                  >
                    Agendamentos
                  </span>
                )}

                <span
                  className={`single__abas-principais__aba ${
                    showMainTabs === "recibosEmitidos" &&
                    "single__abas-principais__aba--active"
                  }`}
                  onClick={() => setShowMainTabs("recibosEmitidos")}
                >
                  Recibos Emitidos
                </span>

                <span
                  className={`single__abas-principais__aba ${
                    showMainTabs === "procedimentos" &&
                    "single__abas-principais__aba--active"
                  }`}
                  onClick={() => setShowMainTabs("procedimentos")}
                >
                  Procedimentos
                </span>

                <span
                  className={`single__abas-principais__aba ${
                    showMainTabs === "planosTratamento" &&
                    "single__abas-principais__aba--active"
                  }`}
                  onClick={() => setShowMainTabs("planosTratamento")}
                >
                  PlanosTratamento
                </span>
              </div>

              <div className="relatoriosContainer">
                {showMainTabs === "financeiro" && (
                  <div className="caixa">
                    <RelatoriosFinanceiro
                      clinicaId={clinica.clinica._id}
                      auth={auth}
                      dadosMapa={dadosMapa}
                    />
                  </div>
                )}

                {showMainTabs === "agendamentos" && (
                  <div className="caixa">
                    <RelatoriosAgendamentos
                      clinicaId={clinica.clinica._id}
                      auth={auth}
                    />
                  </div>
                )}

                {showMainTabs === "orcamentos" && (
                  <div className="caixa">
                    <RelatoriosOrcamentos
                      clinicaId={clinica.clinica._id}
                      auth={auth}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Relatorios;
