import moment from "moment";

const dataGridData = (data) => {
  const rows = data.map((dt) => {
    switch (dt.tipo) {
      case "receita":
        return {
          tipo: "receita",
          cliente: dt.cliente,
          idCliente: dt.idCliente,
          dadosCliente: dt.dadosCliente,
          idContaCorrente: dt.idContaCorrente,
          orcamento: dt.orcamento,
          id: dt.id,
          contaId: dt.contaId,
          nome: dt.entrada ? `Entrada Orçamento -` : `Parcela Orçamento -`,
          data: moment(dt.dataLimitePagamento).format("DD-MM-YYYY"),
          valor:
            dt.quantiaPorPagar > 0
              ? `${dt.quantiaPorPagar.toLocaleString("pt-BR")}$00`
              : `${dt.quantiaPaga.toLocaleString("pt-BR")}$00`,
          receber: "receber",
          estado: dt.estado,
          metodoPagamento: dt.metodoPagamento,
          nrParcela: dt.nrParcela,
          dataLimitePagamento: dt.dataLimitePagamento,
          dataPagamento: dt.dataPagamento,
          parcelaPaga: dt.parcelaPaga,
          documentoEletronico: dt.documentoEletronico,
          saldo: dt.saldo,
          recebidoPor: dt.recebidoPor,
        };
      case "despesa":
        return {
          id: dt.id,
          dataLimitePagamento: dt.dataLimitePagamento,
          dataPagamento: dt.dataPagamento,
          valor: `${dt.valor.toLocaleString("pt-BR")}$00`,
          tipo: dt.tipo,
          estado: dt.estado,
          metodoPagamento: dt.metodoPagamento,
          despesaPaga: dt.despesaPaga,
          descricao: dt.descricao,
          comprovativo: dt.comprovativo,
          saldo: dt.saldo,
          pagaPor: dt.pagaPor,
        };
      case "entrada":
        return {
          id: dt.id,
          dataPagamento: dt.dataPagamento,
          valor: dt.valor ? `${dt.valor.toLocaleString("pt-BR")}$00` : "",
          tipo: dt.tipo,
          estado: dt.estado,
          metodoPagamento: dt.metodoPagamento,
          descricao: dt.descricao,
          comprovativo: dt.comprovativo,
          documentoEletronico: dt.documentoEletronico,
          saldo: dt.saldo,
          entradaRecebida: dt.entradaRecebida,
          cliente: dt.cliente,
          recebidoPor: dt.recebidoPor,
        };
      default:
        return {};
    }
  });

  return rows;
};

export default dataGridData;
