import { useState, useEffect } from "react";
import ElementoParcela from "./ElementoParcela";
import moment from "moment/moment";
//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { v4 as uuidv4 } from "uuid";
import "./ModalAprovarOrcamento.scss";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import InputText from "../../../../../../shared/components/inputs/InputText/InputText";
import toast from "react-hot-toast";
import { useLoading } from "../../../../../../shared/context/LoadingContext";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

import { updateCalculations } from "./discountCalculations";

function ModalAprovarOrcamento({
  open,
  modalData,
  handleCloseModalAprovarOrcamento,
  sendRequest,
  auth,
  contaCorrente,
  updateOrcamentos,
  clinica,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [nrParcelas, setNrParcelas] = useState(modalData.conta.nrParcelas);
  const [totalParcelas, setTotalParcelas] = useState(() => {
    return modalData.conta.parcelas.reduce(
      (sum, parcela) => sum + parcela.quantiaPorPagar,
      0
    );
  });

  const [mensagemErro, setMensagemErro] = useState("");
  const [parcelas, setParcelas] = useState(modalData.conta.parcelas);
  const [showParcelarOrcamento, setShowParcelarOrcamento] = useState(
    modalData.conta.nrParcelas > 1
  );

  const [serviceList, setServiceList] = useState(() =>
    modalData.orcamento.procedimentos.map((p) => ({
      ...p,
      show: true,
      comparticipacao_inps: getComparticipacaoINPS(
        p.tratamento,
        modalData.orcamento.created_at
      ),
    }))
  );

  const [dataCredencial, setDataCredencial] = useState(moment());
  const [nrCredencial, setNrCredencial] = useState("");

  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);

  const { startLoading, stopLoading } = useLoading();

  useEffect(() => {
    const { newSubtotal, newTotal } = updateCalculations(
      serviceList,
      modalData.orcamento.desconto,
      modalData.orcamento.tipoDesconto,
      modalData.orcamento.subtotal
    );
    setSubtotal(newSubtotal);
    setTotal(newTotal);

    // Add this part to update totalParcelas
    const updatedTotalParcelas = parcelas.reduce(
      (sum, parcela) => sum + parcela.quantiaPorPagar,
      0
    );
    setTotalParcelas(updatedTotalParcelas);
  }, [modalData, serviceList]);

  useEffect(() => {
    resetParcelas();
  }, [total, showParcelarOrcamento]);

  const resetParcelas = () => {
    const hasEntrada = parcelas.length > 0 && parcelas[0].entrada;
    let newParcelas = [];

    if (hasEntrada) {
      // Keep the entrada as is
      newParcelas.push({ ...parcelas[0] });

      const remainingTotal = total - parcelas[0].quantiaPorPagar;
      const remainingInstallments = parcelas.length - 1;

      // Distribute the remaining total evenly among the other installments
      const installmentValue = Math.floor(
        remainingTotal / remainingInstallments
      );

      for (let i = 1; i < parcelas.length; i++) {
        if (i === parcelas.length - 1) {
          // Last installment gets any remaining cents
          newParcelas.push({
            ...parcelas[i],
            quantiaPorPagar:
              remainingTotal - (remainingInstallments - 1) * installmentValue,
          });
        } else {
          newParcelas.push({
            ...parcelas[i],
            quantiaPorPagar: installmentValue,
          });
        }
      }
    } else {
      // No entrada, distribute total evenly among existing installments
      const installmentValue = Math.floor(total / parcelas.length);

      for (let i = 0; i < parcelas.length; i++) {
        if (i === parcelas.length - 1) {
          // Last installment gets any remaining cents
          newParcelas.push({
            ...parcelas[i],
            quantiaPorPagar: total - (parcelas.length - 1) * installmentValue,
          });
        } else {
          newParcelas.push({
            ...parcelas[i],
            quantiaPorPagar: installmentValue,
          });
        }
      }
    }

    setParcelas(newParcelas);
    // We're no longer modifying nrParcelas here
  };

  const createParcela = (valor, diasAdicionar, entrada = false) => ({
    id: uuidv4(),
    dataLimitePagamento: moment().add(diasAdicionar, "days"),
    parcelaPaga: false,
    quantiaPaga: 0,
    quantiaPorPagar: valor,
    entrada,
  });

  const nrParcelasChange = (e) => {
    setMensagemErro("");
    const novoNrParcelas = Math.max(1, Number(e.target.value) || 1);
    let newParcelas = [];

    const hasEntrada = parcelas.length > 0 && parcelas[0].entrada;
    if (hasEntrada) {
      newParcelas.push({ ...parcelas[0] });
      const valorRestante = total - parcelas[0].quantiaPorPagar;
      const valorPorParcela = Math.floor(valorRestante / novoNrParcelas);

      for (let i = 0; i < novoNrParcelas; i++) {
        newParcelas.push(
          createParcela(
            i === novoNrParcelas - 1
              ? valorRestante - (novoNrParcelas - 1) * valorPorParcela
              : valorPorParcela,
            i *
              clinica.clinica.configuracoes.configuracoes.validadeParcela
                .validade
          )
        );
      }
    } else {
      const valorPorParcela = Math.floor(total / novoNrParcelas);
      for (let i = 0; i < novoNrParcelas; i++) {
        newParcelas.push(
          createParcela(
            i === novoNrParcelas - 1
              ? total - (novoNrParcelas - 1) * valorPorParcela
              : valorPorParcela,
            (i + 1) *
              clinica.clinica.configuracoes.configuracoes.validadeParcela
                .validade
          )
        );
      }
    }

    setParcelas(newParcelas);
    setNrParcelas(novoNrParcelas);
    setTotalParcelas(total);
  };

  const dataLimitePagamentoChange = (indice, valor) => {
    const parcelasCopy = [...parcelas];
    parcelasCopy[indice].dataLimitePagamento = valor;
    setParcelas(parcelasCopy);
  };

  const entradaChange = (valor) => {
    const parcelasCopy = [...parcelas];
    parcelasCopy[0].quantiaPorPagar = Math.min(Number(valor), total);
    setParcelas(parcelasCopy);
    nrParcelasChange({ target: { value: nrParcelas } });
  };

  const parcelasValueChange = (indice, valor) => {
    const parcelasCopy = [...parcelas];
    parcelasCopy[indice].quantiaPorPagar = Number(valor);

    let totalAteEssaParcela = parcelasCopy
      .slice(0, indice + 1)
      .reduce((sum, p) => sum + p.quantiaPorPagar, 0);

    if (totalAteEssaParcela < total) {
      const valorRestante = total - totalAteEssaParcela;
      const nrParcelasRestantes = parcelasCopy.length - (indice + 1);
      const valorPorParcela = Math.floor(valorRestante / nrParcelasRestantes);

      for (let i = indice + 1; i < parcelasCopy.length; i++) {
        parcelasCopy[i].quantiaPorPagar =
          i === parcelasCopy.length - 1
            ? valorRestante - (nrParcelasRestantes - 1) * valorPorParcela
            : valorPorParcela;
      }
    } else {
      for (let i = indice + 1; i < parcelasCopy.length; i++) {
        parcelasCopy[i].quantiaPorPagar = 0;
      }
    }

    setParcelas(parcelasCopy);
    setTotalParcelas(
      parcelasCopy.reduce((sum, p) => sum + p.quantiaPorPagar, 0)
    );
  };

  const showParcelarOrcamentoHandler = (e) => {
    const isChecked = e.target.checked;
    setShowParcelarOrcamento(isChecked);

    let newParcelas = [];
    const hasEntrada = parcelas.length > 0 && parcelas[0].entrada;

    if (isChecked) {
      // If checked, set to 2 parcelas (or 3 if there's entrada)
      if (hasEntrada) {
        newParcelas.push({ ...parcelas[0] }); // Keep the entrada
        const remainingTotal = total - parcelas[0].quantiaPorPagar;
        const installmentValue = Math.floor(remainingTotal / 2);
        newParcelas.push(
          createParcela(
            installmentValue,
            clinica.clinica.configuracoes.configuracoes.validadeParcela
              .validade,
            false
          )
        );
        newParcelas.push(
          createParcela(remainingTotal - installmentValue, 30, false)
        );
      } else {
        const installmentValue = Math.floor(total / 2);
        newParcelas.push(
          createParcela(
            installmentValue,
            clinica.clinica.configuracoes.configuracoes.validadeParcela
              .validade,
            false
          )
        );
        newParcelas.push(createParcela(total - installmentValue, 30, false));
      }
      setNrParcelas(hasEntrada ? 2 : 2);
    } else {
      // If unchecked, set to 1 parcela (or 2 if there's entrada)
      if (hasEntrada) {
        newParcelas.push({ ...parcelas[0] }); // Keep the entrada
        newParcelas.push(
          createParcela(
            total - parcelas[0].quantiaPorPagar,
            clinica.clinica.configuracoes.configuracoes.validadeParcela
              .validade,
            false
          )
        );
      } else {
        newParcelas.push(createParcela(total, 0, false));
      }
      setNrParcelas(hasEntrada ? 1 : 1);
    }

    setParcelas(newParcelas);
    setTotalParcelas(total);
  };

  const handleRemoveTratamento = (index) => {
    const updatedServiceList = serviceList.map((service, i) =>
      i === index ? { ...service, show: false } : service
    );
    setServiceList(updatedServiceList);
    updateParcelasAfterServiceChange(updatedServiceList);
  };

  const handleAddTratamento = (index) => {
    const updatedServiceList = serviceList.map((service, i) =>
      i === index ? { ...service, show: true } : service
    );
    setServiceList(updatedServiceList);
    updateParcelasAfterServiceChange(updatedServiceList);
  };

  const preSubmit = () => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    const parcelasFinal = parcelas.filter(
      (p) => !p.entrada || p.quantiaPorPagar > 0
    );

    if (totalParcelas !== total) {
      setMensagemErro(
        `A soma dos valores (${totalParcelas}$00) é ${
          totalParcelas < total ? "inferior" : "superior"
        } ao valor total do orçamento (${total}$00).`
      );
      setIsSubmitting(false);
      return;
    }

    const negativeParcela = parcelasFinal.find((p) => p.quantiaPorPagar < 0);
    if (negativeParcela) {
      setMensagemErro("Não é permitido ter parcelas com valores negativos.");
      setIsSubmitting(false);
      return;
    }

    if (parcelasFinal.some((p) => p.quantiaPorPagar <= 0)) {
      setMensagemErro("Preencha todas as parcelas com valor superior a 0.");
      setIsSubmitting(false);
      return;
    }

    const parcelasSemData = parcelasFinal.filter((p) => !p.dataLimitePagamento);
    const parcelasComErroEmData = parcelasFinal.filter(
      (p) =>
        !moment.isMoment(p.dataLimitePagamento) ||
        !p.dataLimitePagamento?.isValid()
    );

    if (!dataCredencial) {
      setMensagemErro("A data de credencial é obrigatória");
      setIsSubmitting(false);
      return;
    } else if (!moment.isMoment(dataCredencial) || !dataCredencial?.isValid()) {
      setMensagemErro("Preencha a data de credencial corretamente.");
      setIsSubmitting(false);
      return;
    }

    if (parcelasSemData.length > 0) {
      setMensagemErro("Preencha as datas de vencimento em todas as parcelas");
      setIsSubmitting(false);
      return;
    }

    if (parcelasComErroEmData.length > 0) {
      setMensagemErro(
        "As datas de vencimento não estão preenchidas corretamente"
      );
      setIsSubmitting(false);
      return;
    }

    const parcelasLength = parcelasFinal.filter((p) => !p.entrada).length;
    if (parcelas[0].entrada) {
      parcelasFinal.forEach((parcela, index) => {
        if (!parcela.entrada) {
          parcela.nrParcela = `${index}/${parcelasLength}`;
        }
        delete parcela.id;
      });
    } else {
      parcelasFinal.forEach((parcela, index) => {
        if (!parcela.entrada) {
          parcela.nrParcela = `${index + 1}/${parcelasLength}`;
        }
        delete parcela.id;
      });
    }

    submit(parcelasFinal);
  };

  const submit = async (parcelasFinal) => {
    startLoading();

    try {
      let planoTratamentoCriado;
      const shownServices = serviceList.filter((s) => s.show === true);
      const hiddenServices = serviceList.filter((s) => s.show === false);
      if (shownServices.length === 0) {
        toast.error("Nenhum procedimento selecionado!");
      } else {
        const procedimentosFiltrados = [];
        let orcamentoRetornado;

        shownServices.forEach((pr) => {
          procedimentosFiltrados.push({
            tratamento: pr.tratamento._id,
            dente: pr.dente,
            faces: pr.faces,
            procedimento_completo: false,
          });
        });

        const formData = new FormData();
        formData.append(
          "procedimentos",
          JSON.stringify(procedimentosFiltrados)
        );
        formData.append("cliente", modalData.orcamento.cliente._id);
        formData.append("clinica", modalData.orcamento.clinica._id);
        formData.append("orcamento", modalData.orcamento._id);
        formData.append("canDelete", true);
        formData.append(
          "medico_responsavel",
          modalData.orcamento.medico_responsavel._id
        );
        formData.append("criadoPor", auth.userId);

        //Gravando plano de tratamento
        try {
          planoTratamentoCriado = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento`,
            "POST",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }
        //Gravando plano de tratamento, transformando orcamento
        //Se orçamentarmos tudo, simplismente atualizamos o orçamento para ficar como transformado
        if (modalData.orcamento.procedimentos.length === shownServices.length) {
          const formData2 = new FormData();
          formData2.append("transformado", true);
          formData2.append(
            "planoTratamento",
            planoTratamentoCriado.planoTratamento._id
          );
          try {
            orcamentoRetornado = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/transformar/${modalData.orcamento._id}`,
              "PATCH",
              formData2,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            console.error("err", err);
          }
        } else {
          //Caso não orçamentarmos tudo, atualizamos o orçamento, e a avaliação
          const formDataOrcamento = new FormData();

          const newProcedimentos = shownServices.map((s) => {
            const { comparticipacao_inps, ...rest } = s;
            return {
              ...rest,
              tratamento: s.tratamento._id,
            };
          });

          formDataOrcamento.append(
            "procedimentos",
            JSON.stringify(newProcedimentos)
          );
          formDataOrcamento.append("transformado", true);
          formDataOrcamento.append("total", total);
          formDataOrcamento.append("beneficio", modalData.orcamento.beneficio);
          formDataOrcamento.append("subtotal", subtotal);
          formDataOrcamento.append("desconto", modalData.orcamento.desconto);
          formDataOrcamento.append(
            "tipoDesconto",
            modalData.orcamento.tipoDesconto
          );
          formDataOrcamento.append("terminado", false);
          formDataOrcamento.append(
            "observacoes",
            modalData.orcamento.observacoes
          );
          formDataOrcamento.append(
            "planoTratamento",
            planoTratamentoCriado.planoTratamento._id
          );

          try {
            orcamentoRetornado = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/${modalData.orcamento._id}`,
              "PATCH",
              formDataOrcamento,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            console.error("err", err);
          }

          //Atualizando a avaliacao
          const newProcedimentosAvaliacao =
            modalData.orcamento.avaliacao.procedimentos.map((procedimento) => {
              if (
                hiddenServices.some(
                  (service) => service.id === procedimento._id
                )
              ) {
                return {
                  ...procedimento,
                  orcamentado: false,
                  tratamento: procedimento.tratamento._id,
                };
              }
              return {
                ...procedimento,
                tratamento: procedimento.tratamento._id,
              };
            });

          const formDataAvaliacao = new FormData();
          formDataAvaliacao.append(
            "procedimentos",
            JSON.stringify(newProcedimentosAvaliacao)
          );
          formDataAvaliacao.append("transformado", true);
          formDataAvaliacao.append(
            "cliente",
            modalData.orcamento.avaliacao.cliente
          );
          formDataAvaliacao.append("atualizadoPor", auth.userId);
          formDataAvaliacao.append(
            "medico_responsavel",
            modalData.orcamento.avaliacao.medico_responsavel
          );
          formDataAvaliacao.append("terminado", false);
          formDataAvaliacao.append(
            "observacoes",
            modalData.orcamento.avaliacao.observacoes
          );

          try {
            await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/avaliacoes/${modalData.orcamento.avaliacao._id}`,
              "PATCH",
              formDataAvaliacao,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            console.error("err", err);
          }
        }

        //Atualizando a conta corrente

        const formData3 = new FormData();

        const filteredDadosConta = {
          ...modalData.conta,
          aprovado: true,
          contaLiquidada:
            parcelasFinal[0].entrada &&
            parcelasFinal[0].quantiaPorPagar === total
              ? true
              : false,
          nrParcelas: parcelasFinal[0].entrada
            ? parcelasFinal.length - 1
            : parcelasFinal.length,
          parcelas: parcelasFinal,
          total: total,
        };

        const contasDaContaCorrente = contaCorrente.contas.filter(
          (conta) => conta.id !== modalData.conta.id
        );
        contasDaContaCorrente.push(filteredDadosConta);

        formData3.append("contas", JSON.stringify(contasDaContaCorrente));
        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/contasCorrentes/${contaCorrente.id}`,
            "PATCH",
            formData3,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }

        if (nrCredencial) {
          try {
            if (!nrCredencial || nrCredencial.trim().length === 0) {
              toast.error("Por Favor introduza o número do credencial");
            } else {
              const formData = new FormData();
              formData.append("dataCredencial", dataCredencial);
              formData.append("nrCredencial", nrCredencial);

              orcamentoRetornado = await sendRequest(
                `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/credencial/${modalData.orcamento._id}`,
                "PATCH",
                formData,
                {
                  Authorization: "Bearer " + auth.token,
                }
              );

              orcamentoRetornado.orcamento.nrCredencial = nrCredencial;
              orcamentoRetornado.orcamento.dataCredencial = dataCredencial;
            }
          } catch (err) {
            console.error("err", err);
          }
        }
        toast.success(`Orçamento aprovado com sucesso!`);
        updateOrcamentos(orcamentoRetornado.orcamento);
        handleCloseModalAprovarOrcamento();
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const nrCredencialChangeHandler = (value) => {
    setNrCredencial(value);
  };

  const updateParcelasAfterServiceChange = (updatedServiceList) => {
    const { newTotal } = updateCalculations(
      updatedServiceList,
      modalData.orcamento.desconto,
      modalData.orcamento.tipoDesconto,
      modalData.orcamento.subtotal
    );

    const updatedParcelas = parcelas.map((parcela, index) => {
      if (index === 0 && parcela.entrada) {
        // Keep the entrada as is
        return parcela;
      } else {
        // Distribute the remaining total evenly
        const remainingTotal =
          newTotal - (parcelas[0].entrada ? parcelas[0].quantiaPorPagar : 0);
        const parcelasCount = parcelas.length - (parcelas[0].entrada ? 1 : 0);
        const newQuantiaPorPagar = Math.floor(remainingTotal / parcelasCount);

        if (index === parcelas.length - 1) {
          // Last parcela gets any remaining cents
          return {
            ...parcela,
            quantiaPorPagar:
              remainingTotal - (parcelasCount - 1) * newQuantiaPorPagar,
          };
        } else {
          return {
            ...parcela,
            quantiaPorPagar: newQuantiaPorPagar,
          };
        }
      }
    });

    setParcelas(updatedParcelas);
    setTotal(newTotal);
    setTotalParcelas(newTotal);
  };

  function getComparticipacaoINPS(tratamento, orcamentoCreationDate) {
    const creationDate = new Date(orcamentoCreationDate);

    // Sort historicoPrecos by date in descending order
    const sortedHistorico = tratamento.historicoPrecos.sort(
      (a, b) => new Date(b.data) - new Date(a.data)
    );

    // Find the most recent price change before or on the orçamento creation date
    const relevantPriceChange = sortedHistorico.find(
      (priceChange) => new Date(priceChange.data) <= creationDate
    );

    // If a relevant price change is found, use its comparticipacao_inps value
    // Otherwise, fall back to the current value in the tratamento object
    return relevantPriceChange
      ? relevantPriceChange.comparticipacao_inps
      : tratamento.comparticipacao_inps;
  }

  return (
    <Modal
      open={open}
      onClose={handleCloseModalAprovarOrcamento}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="fluxo-atendimento__modal__box aprovar-orcamento__modal"
        id="modal__aprovar-orcamento"
      >
        <h1 className="fluxo-atendimento__modal__titulo">
          Aprovar Orçamento {modalData.orcamento.codigo}
        </h1>
        <span className="aprovar-orcamento__modal__regras-aprovacao">
          Ao aprovar esse orçamento, o plano de tratamento é criado
          automaticamente na aba Planos Tratamento e os valores são lançados em
          Débito.
        </span>

        <div className="aprovar-orcamento__modal__procedimentos__container">
          <div
            className={`aprovar-orcamento__modal__procedimentos__container__item ${
              modalData.orcamento.beneficio === "Particular"
                ? " aprovar-orcamento__modal__procedimentos__container__item--particular"
                : ""
            }`}
          >
            <span className="aprovar-orcamento__modal__procedimentos__container__item__title">
              Tratamento
            </span>
            <span className="aprovar-orcamento__modal__procedimentos__container__item__title">
              Dentes/Região
            </span>
            <span className="aprovar-orcamento__modal__procedimentos__container__item__title">
              Valor
            </span>
            {modalData.orcamento.beneficio === "INPS" && (
              <span className="aprovar-orcamento__modal__procedimentos__container__item__title">
                INPS
              </span>
            )}
            <span className="aprovar-orcamento__modal__procedimentos__container__item__title">
              Ação
            </span>
          </div>
          {serviceList &&
            serviceList.length > 0 &&
            serviceList.map((s, index) => (
              <div
                className={`aprovar-orcamento__modal__procedimentos__container__item ${
                  modalData.orcamento.beneficio === "Particular"
                    ? " aprovar-orcamento__modal__procedimentos__container__item--particular"
                    : ""
                }`}
                key={index}
              >
                <span
                  className={
                    s.show === false
                      ? "aprovar-orcamento__modal__procedimentos__container__item__out"
                      : ""
                  }
                >
                  {s.tratamento.designacao}
                </span>
                <span
                  className={
                    s.show === false
                      ? "aprovar-orcamento__modal__procedimentos__container__item__out"
                      : ""
                  }
                >
                  {s.dente}
                </span>
                <span
                  className={
                    s.show === false
                      ? "aprovar-orcamento__modal__procedimentos__container__item__out"
                      : ""
                  }
                >
                  {s.pr_total}
                </span>

                {modalData.orcamento.beneficio === "INPS" && (
                  <span
                    className={
                      s.show === false
                        ? "aprovar-orcamento__modal__procedimentos__container__item__out"
                        : ""
                    }
                  >
                    {s.comparticipacao_inps}
                  </span>
                )}
                {s.show && (
                  <Tooltip title="Remover">
                    <IconButton
                      onClick={() => handleRemoveTratamento(index)}
                      className="modalAprovar__icon"
                    >
                      <CloseIcon className="modalAprovar__icon--remove" />
                    </IconButton>
                  </Tooltip>
                )}

                {!s.show && (
                  <Tooltip title="Adicionar">
                    <IconButton
                      onClick={() => handleAddTratamento(index)}
                      className="modalAprovar__icon"
                    >
                      <AddIcon className="modalAprovar__icon--add" />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            ))}
        </div>

        <div className="aprovar-orcamento__modal__valor-orcamento-container">
          Valor Total do Orçamento:{" "}
          <span className="aprovar-orcamento__modal__valor-orcamento-span">
            {total}$00
          </span>{" "}
          <>
            - Soma dos valores:{" "}
            <span className="aprovar-orcamento__modal__valor-orcamento-span">
              {totalParcelas}$00
            </span>
          </>
        </div>
        <div className="aprovar-orcamento__modal__valor-orcamento-container">
          Desconto:{" "}
          <span className="aprovar-orcamento__modal__valor-orcamento-span">
            {modalData.orcamento.tipoDesconto === "%"
              ? (modalData.orcamento.desconto * 100) /
                  modalData.orcamento.subtotal +
                "%"
              : modalData.orcamento.desconto + "$00"}
          </span>{" "}
        </div>

        <div className="aprovarOrcamento__parcelasCredencial__container">
          <div className="aprovarOrcamento__parcelasCredencial__container__item">
            {parcelas && parcelas[0].entrada && (
              <div className="aprovar-orcamento__modal__entrada-container">
                <ElementoParcela
                  dataLimitePagamento={parcelas[0].dataLimitePagamento}
                  quantiaPorPagar={parcelas[0].quantiaPorPagar}
                  indiceParcela={0}
                  entrada={true}
                  entradaChange={entradaChange}
                  dataLimitePagamentoChange={dataLimitePagamentoChange}
                  parcelasValueChange={parcelasValueChange}
                />
              </div>
            )}
            <div className="aprovar-orcamento__modal__descricao-container">
              {parcelas && parcelas[0].entrada && (
                <>
                  Com saldo de
                  <span className="aprovar-orcamento__modal__saldo">
                    {" "}
                    {total - parcelas[0].quantiaPorPagar}$00{" "}
                  </span>
                </>
              )}

              <div className="parcelarOrcamentoDiv">
                <label htmlFor="" className="parcelarOrcamento">
                  <input
                    type="checkbox"
                    className="checkbox"
                    onChange={showParcelarOrcamentoHandler}
                    checked={showParcelarOrcamento}
                  />
                  Parcelar Orcamento
                </label>
                {showParcelarOrcamento && (
                  <div className="parcelarOrcamentoDivHorizontal">
                    {parcelas && parcelas[0].entrada
                      ? "parcelado em"
                      : "Parcelar em"}

                    <input
                      type="number"
                      className="aprovar-orcamento__modal__nrParcelas"
                      value={nrParcelas}
                      onChange={nrParcelasChange}
                      min={1}
                    />
                    {nrParcelas === 1 ? "vez" : "vezes"}
                  </div>
                )}
              </div>
            </div>
            {/* Caso houver valor de entrada */}
            {parcelas &&
              parcelas[0].entrada &&
              parcelas.map(
                (parcela, index) =>
                  !parcela.entrada && (
                    <ElementoParcela
                      key={index}
                      dataLimitePagamento={parcela.dataLimitePagamento}
                      quantiaPorPagar={parcela.quantiaPorPagar}
                      indiceParcela={index}
                      totalParcelas={nrParcelas}
                      entradaChange={entradaChange}
                      dataLimitePagamentoChange={dataLimitePagamentoChange}
                      parcelasValueChange={parcelasValueChange}
                      comEntrada={true}
                    />
                  )
              )}
            {/* Caso não houver valor de entrada */}
            {parcelas &&
              !parcelas[0].entrada &&
              parcelas.map((parcela, index) => (
                <ElementoParcela
                  key={index}
                  dataLimitePagamento={parcela.dataLimitePagamento}
                  quantiaPorPagar={parcela.quantiaPorPagar}
                  indiceParcela={index}
                  totalParcelas={nrParcelas}
                  parcelasValueChange={parcelasValueChange}
                  dataLimitePagamentoChange={dataLimitePagamentoChange}
                  comEntrada={false}
                />
              ))}
          </div>
          {modalData.orcamento.beneficio === "INPS" && (
            <div className="modalCredencialContainer aprovarOrcamento__parcelasCredencial__container__item">
              <h2>Inserir dados de crendencial</h2>
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                className="adicionar-despesa__modal__container--span1"
              >
                <DesktopDatePicker
                  className="valorDataCaixa__fields--field muiDatePicker"
                  label="Data de Inicio*"
                  inputFormat="DD-MM-YYYY"
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(value) => setDataCredencial(value)}
                  value={dataCredencial}
                />
              </LocalizationProvider>

              <InputText
                className="adicionar-entrada__modal__container--span2"
                label="Nº do Credencial*"
                initialValue={nrCredencial}
                handleChange={nrCredencialChangeHandler}
              />
            </div>
          )}
        </div>
        {mensagemErro && (
          <div className="fluxo-atendimento__modal__erros">{mensagemErro}</div>
        )}
        <div className="fluxo-atendimento__modal__bottom_btns">
          <span
            className="cancel-btn"
            onClick={handleCloseModalAprovarOrcamento}
            style={{ display: "block" }}
          >
            Fechar
          </span>
          <span
            className="blue-button"
            disabled={isSubmitting}
            onClick={preSubmit}
            style={{ display: "block" }}
          >
            {isSubmitting ? "Aprovando..." : "Aprovar"}
          </span>
        </div>
      </Box>
    </Modal>
  );
}

export default ModalAprovarOrcamento;
