// useWebSocket.js
import { useEffect, useRef, useCallback, useContext } from "react";
import { AuthContext } from "../context/auth-context";
const MAX_RECONNECT_ATTEMPTS = 5;
const RECONNECT_INTERVAL = 1000;

const useWebSocket = (url, onMessage, dependencies) => {
  const socketRef = useRef(null);
  const auth = useContext(AuthContext);
  const reconnectTimeoutRef = useRef(null);
  const reconnectAttemptsRef = useRef(0);
  // Start with 1 seconds

  const connectWebSocket = useCallback(() => {
    if (!auth.token) {
      console.log("🟡 No auth token - skipping WebSocket connection");
      return;
    }

    // Clear any existing reconnection timeouts
    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current);
    }

    // Add authentication token to WebSocket URL
    const wsUrl = `${url}?token=${auth.token}`;
    console.log("🟡 Attempting WebSocket connection");

    socketRef.current = new WebSocket(wsUrl);

    socketRef.current.onopen = () => {
      console.log("🟢 WebSocket connected for user:", auth.userId);
      // Reset reconnection attempts on successful connection
      reconnectAttemptsRef.current = 0;
    };

    socketRef.current.onclose = (event) => {
      console.log("🔴 WebSocket closed:", {
        code: event.code,
        reason: event.reason,
        userId: auth.userId,
      });

      // Implement exponential backoff for reconnection
      if (reconnectAttemptsRef.current < MAX_RECONNECT_ATTEMPTS) {
        const timeout =
          RECONNECT_INTERVAL * Math.pow(2, reconnectAttemptsRef.current);
        console.log(`Attempting reconnection in ${timeout / 1000} seconds...`);

        reconnectTimeoutRef.current = setTimeout(() => {
          console.log("Executing reconnection attempt...");
          reconnectAttemptsRef.current += 1;
          connectWebSocket();
        }, timeout);
      } else {
        console.log(
          "Maximum reconnection attempts reached. Manual reconnection required."
        );
      }
    };

    socketRef.current.onerror = (error) => {
      console.error("WebSocket encountered an error:", error);
    };

    const messageHandler = (event) => {
      console.log("🟡 WebSocket message received:", {
        userId: auth.userId,
        data: JSON.parse(event.data),
      });
      const parsedData = JSON.parse(event.data);
      onMessage(parsedData);
    };

    socketRef.current.addEventListener("message", messageHandler);

    return () => {
      console.log("🟡 Cleaning up message handler for user:", auth.userId);
      socketRef.current?.removeEventListener("message", messageHandler);
    };
  }, [auth.token, auth.userId, url, onMessage]);

  useEffect(() => {
    console.log("🟡 WebSocket hook effect triggered for user:", auth.userId);

    if (auth.token) {
      connectWebSocket();
    }

    // Cleanup function for the entire hook
    return () => {
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
      if (socketRef.current) {
        console.log(
          "🟡 Cleaning up WebSocket connection for user:",
          auth.userId
        );
        socketRef.current.close();
        socketRef.current = null;
      }
    };
  }, [connectWebSocket, auth.token, auth.userId]);
};

export default useWebSocket;
